/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { Select } from 'antd';

import { onSelectChange, usePrevious } from '../../helpers';

import './index.scss';
import isNaN from 'lodash/isNaN';

const { Option } = Select;
const days = [];
const years = [];
const monthes = [];
for (let index = 1; index <= 31; index++) {
  days.push(index);
}
for (let index = new Date().getFullYear(); index >= 1900; index--) {
  years.push(index);
}

for (let index = 0; index <= 12; index++) {
  monthes.push(index);
}

const DateDropdown = ({ date, setDate, onChange, disabled }) => {
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useMemo(() => {
    const defaultDate = `${year}-${month}-${day}`;
    if (date !== null && date !== undefined) {
      const yearParsed = isNaN(parseInt(date.split('-')[0], 10))
        ? null
        : parseInt(date.split('-')[0], 10);

      const monthedParsed = isNaN(parseInt(date.split('-')[1], 10))
        ? null
        : parseInt(date.split('-')[1], 10);
      const dayParsed = isNaN(parseInt(date.split('-')[2], 10))
        ? null
        : parseInt(date.split('-')[2], 10);

      setYear(yearParsed);
      setMonth(monthedParsed);
      setDay(dayParsed);
    }
    if (date === null) {
      setDate(defaultDate);
    }
  }, [date]);

  const previousDay = usePrevious(day);

  const initialRender = previousDay === undefined;

  useEffect(() => {
    const dateOfBirth = `${year}-${month}-${day}`;

    if (!initialRender || (year || month || day)) {
      onChange({
        dateOfBirth,
      });
    }

    setDate(dateOfBirth);
  }, [day, month, year]);

  return (
    <div className="DateDropdown-Container">
      <div className="DateDropdown_Item">
        <Select
          showSearch
          disabled={disabled}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={onSelectChange(setDay)}
          value={day}
          placeholder="Select day"

        >
          {days.map((day, index) => (
            <Option key={index} value={day}>
              {day}
            </Option>
          ))}
        </Select>
      </div>
      <div className="DateDropdown_Item">
        <Select
          showSearch
          disabled={disabled}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={onSelectChange(setMonth)}
          placeholder="Select month"
          value={month}

        >
          {monthes.map((elem, index) => {
            if (index === 0) return null;
            return (
              <Option key={index} value={elem}>
                {elem}
              </Option>
            );
          })}
        </Select>
      </div>

      <div className="DateDropdown_Item">
        <Select
          showSearch
          disabled={disabled}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={onSelectChange(setYear)}
          placeholder="Select year"
          value={year}

        >
          {years.map((year, index) => (
            <Option key={index} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default DateDropdown;
