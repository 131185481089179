/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { Table, Button, Tabs, Space,Modal,Form,Row,Col,Select,Input, notification, message } from 'antd';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import useAsyncEffect from '@n1ru4l/use-async-effect';
import isEmpty from 'lodash/isEmpty';
import fp from 'lodash/fp';
import { CancelToken } from 'axios';

import { $currentUser } from '../../models';
import useResizer from '../../helpers/resizer';
import { client } from '../../helpers/http';
import {useHistory} from "react-router-dom";
import './index.scss';
import useWindowSize from "../../helpers/useWindowSize";
import { uidAnalyses } from "../../helpers/constants";

import { BlurredDivWithMessage, ScheduleButton } from "./components";
import {
  onSelectChange,
  createSelectOptions,

} from '../../helpers';
import Labeled from '../Labeled';
import {

  getAllOrganizations,

} from '../../state/user/api';

const moment = extendMoment(Moment);

const useScheduleFormating = (
  calendar,
  onSlotChange,
  selectedTime,
  addNewAppointment,
  isAdHoc,
  isAptNow,
  isAdHocRebook,
  isAdHocReschedule,
  appointmentParentData,
  leftDate,
  nextDate,
  isTCmanager,
  appointmentData,
  isSuperManager,
  isFromMyTests,
  isFromCCB,
  // users,
  hospitalId,
  analyse,
  data,
  organizationDropdown,
  count,
  columnKey,
  setColumnKey,
  isOpenCancelModal,
  setIsOpenCancelModal,
  setSlotDate,
  setSlotTime,

) =>
  useMemo(() => {
    // if (isEmpty(calendar)) return [[],[]];
    console.log("calendar is ####",calendar);

let configUrl=window.location.href;
console.log("configUrl",configUrl);
let UrlUID=false;
if(configUrl.includes("schedule")){
  UrlUID=true;
  if(configUrl.includes("schedule/edit")){
  UrlUID=false;
  }


}


    const range = Array.from(moment.range(leftDate, nextDate).by('days'));

    const dates = isEmpty(calendar) ?
      [...range.map((date) => [moment(date).format('YYYY-MM-DD'), {
        '06_00': { scheduler: null, capacity: null, booked: null, available: null, colour: "", fake: true },
        '06_15': { scheduler: null, capacity: null, booked: null, available: null, colour: "", fake: true },
        '06_30': { scheduler: null, capacity: null, booked: null, available: null, colour: "", fake: true },
        '06_45': { scheduler: null, capacity: null, booked: null, available: null, colour: "", fake: true },
      }])]
      : fp.flow(
        fp.entries,
        fp.flatMap(([, monthData]) => fp.entries(monthData)),
        fp.map(([date, value]) => [date.replace(/_/g, '-'), value]),
      )(calendar);


    const columns = [
      {
        dataIndex: 'time',
        title: 'Time',
        align: 'center',
        fixed: 'left',
      },

      ...dates.map(([date]) => {
        const formattedDate = moment(date).format('ddd D MMM');
        const formattedDate1 = moment(date).format('YYYY-MMM-DD');
        const formattedTime = moment(date).format('hh:mm:ss');
        return {
          dataIndex: formattedDate,
          title: formattedDate,
          key: date,
          align: 'center',
          onHeaderCell: (column,record) => {
            return {
              onClick: () => {
                console.log(calendar)
                // console.log('onClick',formattedDate1<moment().format('YYYY-MMM-DD')?'expire':'not expire');
                // console.log(formattedTime< moment().format( 'hh:mm:ss') &&formattedDate1<moment().format('YYYY-MMM-DD') ?'time expired':'time not expires');
                {!isEmpty(calendar) && (
                setColumnKey(column['key']))}
                {!isEmpty(calendar) && (
                setSlotDate('')
                )}
                {!isEmpty(calendar) && (
                setSlotTime('')
                )}
                {!isEmpty(calendar) && (
                setIsOpenCancelModal(true)
                )}
              }
            };

          },

          onCell:(record, rowIndex) => {
            const slot = record[date];
            return {
              onClick: event => {

                console.log("event is",slot?.status=='valid');
                {slot?.status=='valid' &&(
                setColumnKey('')
                )}
                {slot?.status=='valid' &&(
                setSlotDate(moment(formattedDate1).format('YYYY-MM-DD'))
                )}
                {slot?.status=='valid' &&(
                setSlotTime(record['time'])
                )}
                {slot?.status=='valid' &&(
                UrlUID?setIsOpenCancelModal(true):undefined
                )}

              },
            };
          },


          render: (text, record,index) => {
            const slot = record[date];
            console.log("columns is ",columnKey);
            const secondPartDisabled =
              isAdHoc && appointmentParentData && slot?.available === 0;
            return (

              <ScheduleButton
                isFromMyTests={isFromMyTests}
                isFromCCB={isFromCCB}
                analyseUid={appointmentData?.analyse?.uid}
                isTCmanager={isTCmanager}
                isSuperManager={isSuperManager}
                isAdHoc={isAdHoc}
                isAptNow={isAptNow}
                slot={slot}
                isAdHocRebook={isAdHocRebook}
                isAdHocReschedule={isAdHocReschedule}
                appointmentParentData={appointmentParentData}
                vaccinationPart={appointmentData?.vaccinationPart}
                onSlotChange={onSlotChange}
                secondPartDisabled={secondPartDisabled}
                selectedTime={selectedTime}
                uidAnalyses={uidAnalyses}
                // users={users}
                testCenter={hospitalId}
                procedure={analyse}
                datas={data}
                organizationDropdown={organizationDropdown}
                count={count}
              />
            );
          },
        };
      }),
    ];


    const partsOfDay = fp.flow(
      // flatten all slots information
      fp.flatMap(([date, slots]) =>
        fp.flow(
          fp.entries,
          fp.map(([time, slot]) => ({
            ...slot,
            date,
            time: moment(`${date}T${time.replace(/_/g, ':')}`),
            slot: moment(`${date}T${time.replace(/_/g, ':')}`).format('HH:mm'),
          })),
        )(slots),
      ),
      fp.orderBy(['slot', 'date'], ['asc', 'asc']),
      // filter out unavailable slots
      fp.filter(
        (slot) =>
          slot.capacity>=0
          || slot.fake
      ),
      // group by part of day
      fp.groupBy((slot) => (slot.slot < '13:00' ? 'Morning' : 'Afternoon')),
      fp.entries,
      fp.map(([partOfDay, slots]) => ({
        title: partOfDay,
        slots: fp.flow(
          // group into rows by slot time
          fp.groupBy('slot'),
          fp.entries,
          fp.map(([time, days]) =>
            days.reduce(
              (acc, day) => ({ ...acc, [day.time.format('YYYY-MM-DD')]: day }),
              { time },
            ),
          ),
        )(slots),
      })),
    )(dates);

    return [columns, partsOfDay];
  }, [selectedTime, calendar, onSlotChange]);



const ScheduleView = React.memo(
  ({
    hospitalId,
    slot,
    onSlotChange,
    editMode,
    addNewAppointment,
    analyse,
    isAdHoc,
    isAptNow,
    isAdHocRebook,
    isAdHocReschedule,
    calendar,
    setCalendar,
    setLastCalendarRequestParams = () => { },
    appointmentParentData,
    appointmentData,
    isFromMyTests,
    isFromCCB,
    vaccinationPart,
    users,
    search,
    changeUsersSearch,
    changeStatus,
  }) => {

    console.log("editMode ",vaccinationPart);

    const [tableRect, setTableRect] = useState({});

    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const [data, setdata] = useState([]);
    const [slotDate, setSlotDate] = useState("");
    const [slotTime, setSlotTime] = useState("");
    const [count, setCount] = useState(0);
    const[selectedRowsData,setSelectedRowsData] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const[searchedValue,setSearchedValue] = useState("");
    const [organization,setOrganizationsValue] =useState([]);
    const [organizationDropdown, setOrganizationDropdown] = useState([]);
    const [columnKey, setColumnKey] = useState('');

    const windowSize = useWindowSize();
    const [limit,setLimit] = useState(5);
    const [offset,setOffset] = useState(0);
    const [form] = Form.useForm();
    const history = useHistory();
    const currentUserIsSuperManager = $currentUser
      .getState()
      ?.role?.includes?.('super');
    const isTCmanager = $currentUser
      .getState()
      ?.role?.includes?.('test_centre');

    useEffect(() => {
      const node = document.querySelector('.ant-table-tbody');
      if (node) {
        setTableRect(node.getBoundingClientRect());
      }
    }, [calendar, windowSize]);

//     let configUrl=window.location.href;
// console.log("configUrl",configUrl);
// let UrlUID=false;
// if(configUrl.includes("schedule")){
//   UrlUID=true;
// }

console.log("partsOfDay is",partsOfDay,columns);
    const loadUsers=async()=>{
      setLoading(true);
      let response=await client.get(`/api/v1/users/?consent_approval=&duplicate_check_statuses=in_progress%2Csucceeded&management=true&limit=${limit}&offset=${offset}&ordering=first_name&search=${searchedValue}&verifications=verified%2Cin_progress%2Cnot_verified&organizations=${organization?organization:''}`);
           console.log("count is",count);
           setCount(response.data.count);
           console.log("33333333",response.data.results);
           setdata(response.data.results);
           setLoading(false);

    }
    useEffect(async()=>{
      loadUsers();

     },[offset,searchedValue,organization,limit]);

useEffect(() => {

    getAllOrganizations().then((organizations) => {
      setOrganizationDropdown(organizations.data.results);
    });
  }, []);








    const createIntervalForChildAppointment = (parent) => {
      const { bookingDate, covidDaysInterval, covidLastDuration } = parent;
      const startDate = moment(bookingDate).add(covidDaysInterval, 'days');
      const endDate = moment(startDate).add(covidLastDuration - 1, 'days');
      return { from: startDate, till: endDate };
    };

    const [selectedPartIndex, setPart] = useState('0');
    const [loading, setLoading] = useState(false);

    const isMobile = useResizer();
    const daysToShow = isMobile ? 1 : 4;
    const [intervalToShow, setIntervalToShow] = useState({
      from: null,
      till: null,
    });

    useMemo(() => {
      if (
        appointmentParentData &&
        (!currentUserIsSuperManager || (currentUserIsSuperManager && !isAdHoc))
      ) {
        setIntervalToShow(
          createIntervalForChildAppointment(appointmentParentData),
        );
      }
    }, [appointmentParentData, hospitalId]);

    const getNextDate = (leftDate) => {
      let daysGap = daysToShow - 1;
      // console.log("intervalToShow is",intervalToShow);
      if (intervalToShow.till) {
        const diffBetweenNextAndIntervalDate = intervalToShow.till.diff(
          moment(leftDate)
            .clone()
            .add(daysToShow - 1, 'days'),
          'days',
          false,
        );
        daysGap =
          diffBetweenNextAndIntervalDate > 0
            ? daysToShow - 1
            : daysToShow + diffBetweenNextAndIntervalDate - 1;
      }
      return moment(leftDate).clone().add(daysGap, 'days');
    };

    const [leftDate, setLeftDate] = useState(null);
    const nextDate = useMemo(() => getNextDate(leftDate), [
      leftDate,
      daysToShow,
    ]);

    const goForward = useCallback(() => {
      setLeftDate((l) => {
        const defaultNextDate = moment(l).clone().add(daysToShow, 'days');
        const finalDate = defaultNextDate;
        if (intervalToShow.till) {
          // const diffBetweenNextAndIntervalDate = intervalToShow.till.diff(
          //   l,
          //   'days',
          //   false,
          // );
          // const finalDate =
          //   diffBetweenNextAndIntervalDate < daysToShow
          //     ? moment(l).clone().add(diffBetweenNextAndIntervalDate, 'days')
          //     : defaultNextDate;
        }

        return finalDate;
      });
    }, [daysToShow, intervalToShow.till]);

    const goBack = useCallback(() => {
      setLeftDate((l) => {
        const defaultNextDate = moment(l).subtract(daysToShow, 'days');
        if (moment().isAfter(defaultNextDate)) return moment();
        return defaultNextDate;
      });
    }, [daysToShow]);

    useEffect(() => {
      onSlotChange(null);
      setPart('0');
      setCalendar(null);
    }, [hospitalId]);

    const todayCalendarDayIsExpired = (date) => {
      const isExpired =
        // eslint-disable-next-line no-useless-escape
        +date.endTime.replace(/\:/g, '') <
        // eslint-disable-next-line no-useless-escape
        +moment().format('HH:mm:ss').replace(/\:/g, '');
      return isExpired;
    };

    const getDataInFuture = (calendarData) => {
      const nearestCalendar = calendarData?.data?.results
        ?.reverse()
        ?.find(
          (calendar) =>
            moment().isBetween(
              moment(calendar.startDate),
              moment(calendar.endDate),
            ) ||
            (moment().format('YYYY-MM-DD') === calendar.startDate &&
              !todayCalendarDayIsExpired(calendar)) ||
            (moment().format('YYYY-MM-DD') === calendar.endDate &&
              !todayCalendarDayIsExpired(calendar)),
        );

      if (nearestCalendar) {
        return {
          isFuture: false,
          date: nearestCalendar,
        };
      }

      const futureDate = calendarData?.data?.results?.find((calendar) =>
        moment().isBefore(moment(calendar.startDate)),
      );

      return { isFuture: true, date: futureDate };
    };

    useAsyncEffect(
      function* (onCancel) {
        try {
          if (!hospitalId || !leftDate) return;
          setLoading(true);
          const source = CancelToken.source();
          onCancel(() => source.cancel('State changed'));
          const params = {
            adHoc:
              addNewAppointment ||
              isAdHoc ||
              isAdHocReschedule ||
              isAdHocRebook ||
              isAptNow,
            hospital: hospitalId,
            startDate: leftDate.format('YYYY-MM-DD'),
            endDate: nextDate.format('YYYY-MM-DD'),
            analyse: analyse?.id,
            excludeChecks: appointmentParentData ? true : undefined,
          };

          const newScheduleData = yield client
            .get('/api/v1/calendar/', {
              params,
              cancelToken: source.token,
            })
            .then((res) => res.data);
          console.log("newScheduleData are",newScheduleData);
          setCalendar(newScheduleData);
          setLastCalendarRequestParams(params);
        } finally {
          setLoading(false);
        }
      },
      [nextDate, leftDate],
    );

    useAsyncEffect(
      function* (onCancel) {
        try {
          if (!hospitalId) return;
          setLoading(true);
          const source = CancelToken.source();
          onCancel(() => source.cancel('State changed'));

          const calendarData = yield client.get('/api/v1/schedules/?limit=100', {
            params: {
              general: false,
              hospital: hospitalId,
              analyse: analyse?.id,
              ordering: '-start_date',
            },
            cancelToken: source.token,
          });
         console.log("calendarData is",calendarData);
          if (intervalToShow.from && intervalToShow.till) {
            setLeftDate(intervalToShow.from);
            return;
          }
          const futureData = getDataInFuture(calendarData);
          console.log("futureData",futureData);
          if (futureData?.isFuture) {

            //
            let newLength=calendarData.data.results.length;
            let data=calendarData.data.results[newLength-1]

            let lastDate=data?.startDate;
            let latestAppointmentDate='';

            for(let i=0;i<newLength;i++){
              if(calendarData.data.results[i].slotCapacity>0&&calendarData.data.results[i].startDate>moment().format('YYYY-MM-DD')){
                latestAppointmentDate=calendarData.data.results[i].startDate;
                break;
              }
            }


            const date1 = new Date(latestAppointmentDate);
            const date2 = new Date(moment().format('YYYY-MM-DD'));
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if(latestAppointmentDate.length==0){
              const newdate1 = new Date(lastDate);
              const newdate2 = new Date(moment().format('YYYY-MM-DD'));
              const newdiffTime = Math.abs(newdate2 - newdate1);
              const newdiffDays = Math.ceil(newdiffTime / (1000 * 60 * 60 * 24));
              setLeftDate(moment().clone().add(newdiffDays+1,'days'));
            }
            else{
            setLeftDate(moment().clone().add(diffDays,'days'));
            }
            //
            const newLeftDate = moment(futureData?.date?.startDate);
            setLeftDate(newLeftDate);
          } else if (todayCalendarDayIsExpired(futureData?.date)) {

            // start
            if(!futureData?.isFuture){
            let newLength=calendarData.data.results.length;
            let data=calendarData.data.results[newLength-1]
            let lastDate=data?.startDate;
            let latestAppointmentDate='';
            for(let i=0;i<newLength;i++){
              if(calendarData.data.results[i].slotCapacity>0&&calendarData.data.results[i].startDate>moment().format('YYYY-MM-DD')){
                latestAppointmentDate=calendarData.data.results[i].startDate;
                break;
              }
            }
            const date1 = new Date(latestAppointmentDate);
            const date2 = new Date(moment().format('YYYY-MM-DD'));
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if(latestAppointmentDate.length==0){
              const newdate1 = new Date(lastDate);
              const newdate2 = new Date(moment().format('YYYY-MM-DD'));
              const newdiffTime = Math.abs(newdate2 - newdate1);
              const newdiffDays = Math.ceil(newdiffTime / (1000 * 60 * 60 * 24));
              setLeftDate(moment().clone().add(newdiffDays+1,'days'));
            }
            else{

            setLeftDate(moment().clone().add(diffDays,'days'));
            }
          }
            // end



            // setLeftDate(moment().clone().add(1, 'days'));
          } else {
            setLeftDate(moment());
          }
        } finally {
          setLoading(false);
        }
      },
      [hospitalId],
    );


    const [columns, partsOfDay] = useScheduleFormating(
      calendar,
      onSlotChange,
      slot,
      addNewAppointment,
      isAdHoc,
      isAptNow,
      isAdHocRebook,
      isAdHocReschedule,
      appointmentParentData,
      leftDate,
      nextDate,
      isTCmanager,
      appointmentData,
      currentUserIsSuperManager,
      isFromMyTests,
      isFromCCB,
      // users,
      hospitalId,
      analyse,
      data,
      organizationDropdown,
      count,
    columnKey,
    setColumnKey,
    isOpenCancelModal,
    setIsOpenCancelModal,
    setSlotDate,
  setSlotTime,


    );



  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selected data",selectedRowKeys);
      setSelectedRowsData(selectedRowKeys);
      setSelectedRow(selectedRows);


    }};


const changePagination=(pagination)=>{
  console.log("inside pagination");
setLimit(pagination?.pageSize)
setOffset((pagination?.current - 1) * pagination?.pageSize)
}
  const handleCancelModal = (record,value) => {

    let keys=Object.keys(record)[1]

    setSlotDate(keys);
    setSlotTime(record[keys]?.['slot']);
    form.resetFields();
    setIsOpenCancelModal(value)
    setSelectedRowsData([]);
    setLimit(5);

  };

  const usersColumns = useMemo(() => [
    {
      key: 'email',
      title: 'Users',
      width: 270,
      ellipsis: true,
      className: 'usersHeadline',
      filteredValue:[searchedValue],
       render: (_, record) => record?.email,
    },

    {
      key: 'organization',
      title: 'Organization',
      ellipsis: true,
      render: (_, record) => record?.organization?.title,
    },



  ]);



  const handleConfirm = async()=>{

    let arr = [];
    if(columnKey!=''){
    console.log("key data is",partsOfDay);
    console.log("morning",partsOfDay?.[selectedPartIndex]?.title);
    let title=partsOfDay?.[selectedPartIndex]?.title;
    var newArray = partsOfDay.filter(function (data)
    {
      return data.title==title;

    }
    );

    // partsOfDay?.map((data)=>{
    //   data.title==title
    // })

    // partsOfDay?.[0]?.title==partsOfDay?.[selectedPartIndex]?.title
    console.log("newArray",newArray);
    newArray?.[0]?.slots?.map((key)=>{
      console.log("key and value is",key[columnKey])

      arr.push({
        slot:key[columnKey]?.slot,
        available: key[columnKey]?.available,

    });

    })
  }


    setLoading(true);

    let list=[]
    for(let i=0;i<=selectedRowsData.length;i++){
      list.push(selectedRowsData.id);
    }
   let data={
      'list':selectedRowsData,
      'procedure':analyse,
      'hospital':hospitalId,
      'date':slotDate,
      'time':slotTime,
      'search':searchedValue,
      'organization':organization,
      'data':arr,
      'columnkey':columnKey,


    }

    const response=await client.post(`/api/v1/send_email/`,data);
    notification.success({ message: 'Email sent', duration: 5 });

    setSelectedRow([]);
    setSelectedRowsData([]);

    setLoading(false);
    setLimit(5);
    form.resetFields();
    setIsOpenCancelModal(false);

    history.push('/schedule');

  }

    return (
      <div className={classnames("ScheduleView", {
        blurredInfo: isEmpty(calendar),
      })}>
        {editMode && (
          <div className="Schedule_CelendarTitle">Select new time slot</div>
        )}
        <Tabs
          className="Schedule_Tabs"
          animated={false}
          onChange={setPart}
          tabBarExtraContent={
            <Space>
              <Button
                type="primary"
                className="changePeriodBtn"
                ghost
                disabled={
                  intervalToShow.from
                    ? intervalToShow.from.isAfter(leftDate) ||
                    intervalToShow.from.isSame(leftDate)
                    : moment().isAfter(leftDate)
                }
                onClick={goBack}
              >
                Earlier
              </Button>
              <Button
                className="changePeriodBtn"
                type="primary"
                ghost
                disabled={
                  intervalToShow.till && leftDate
                    ? intervalToShow.till.isBefore(
                      leftDate.clone().add(daysToShow, 'days'),
                    ) || intervalToShow.till.isSame(leftDate)
                    : false
                }
                onClick={goForward}
              >
                Later
              </Button>
            </Space>
          }
          activeKey={selectedPartIndex}
        >
          {partsOfDay.map((part, partIndex) => (
            <Tabs.TabPane tab={part.title} key={partIndex} />
          ))}
          {!partsOfDay.length && <Tabs.TabPane tab="" key="0" />}
        </Tabs>
        <Table
          loading={loading}
          bordered
          pagination={false}
          columns={columns}
          className="Schedule_Table"
          dataSource={partsOfDay?.[selectedPartIndex]?.slots}
          // onClick={(e)=> console.log("cell",e)}

          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: event => {
          //       //  setRecordData(record);
          //       setColumnKey('');
          //       UrlUID?handleCancelModal(record,true):undefined;
          //     },
          //   };
          // }}





          locale={{
            emptyText: (
              <>
                No available slots on this day in the{' '}
                {partsOfDay?.[selectedPartIndex]?.title ?? 'Morning'}
              </>
            ),
          }}
        />
        {isEmpty(calendar) && !loading && (<BlurredDivWithMessage tableRect={tableRect} />)}

        <Modal
        width={800}
        // bodyStyle={{height: 700}}
        visible={isOpenCancelModal}
        onCancel={() => handleCancelModal(false)}
        onOk={form.submit}
        okText="Send Email"
        cancelButtonProps={{ className: 'modal-cancel__cancelBtn' }}
        okButtonProps={{ className: 'modal-cancel__confirmBtn',loading:loading}}
        //,disabled: selectedRowsData?.length>0?false:true
        centered
      >
        <Form
          form={form}
          onFinish={handleConfirm}
          className='modal-cancel'
        >
          <span><h3>Sending Available Appointments on <b>{columnKey!=''?columnKey:slotDate}</b> {columnKey==''?'Appointment time is':undefined} <b>{columnKey==''?slotTime:undefined}.</b></h3></span>

        <Row>
          <Col xs={24} md={12}>
          <Labeled>Search Users</Labeled>
        <Input.Search
        placeholder='Search user by email/name'
        style={{marginBottom:10}}
        // onSearch={(value)=>{
        //   setSearchedValue(value);
        // }}
        onChange={(e)=>{
          setSearchedValue(e.target.value);
          setOffset(0);
        }}
        >

        </Input.Search>
        </Col>
        <Col xs={24} md={12}>
          <Labeled>Organization</Labeled>
        <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  // disabled={userHasOrg && !isSuper}
                  showSearch
                  allowClear
                  mode="multiple"

                  placeholder="Select organisation"
                  style={{marginBottom:10,width:'100%',marginLeft:4}}

                  onChange={(e)=>{
                    setOrganizationsValue(e);
                    setOffset(0);
                  }}
                >
                  {createSelectOptions(organizationDropdown)}
                </Select>
        </Col>
        </Row>

        <Table
        rowKey="id"
        columns={usersColumns}
        dataSource={data}
        pagination={{
          pageSize:limit,
          total:count,


        }}
        size={'small'}
        bordered={true}
        tableLayout={'auto'}

        onChange={changePagination}
       loading={loading}
        // onRow={onRow}
        // rowSelection={{
        //   onSelectAll: selected => {
        //     if (selected) {
        //       setSelectedRowsData({ selectedRowKeys: data.map(row => row.id) });
        //     } else {
        //       setSelectedRowsData({ selectedRowKeys: data.map(row => row.id) });
        //       ({ selectedRowKeys: [] });
        //     }
        //   },
        //   selectedRowKeys: selectedRowsData
        // }}



        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys:selectedRowsData,
          type:'checkbox',
          ...rowSelection,
          // onSelectAll:(record)=>{
          //   console.log("hello asif",record,data);

          //   setSelectedRowsData({ selectedRowKeys: data.map(row => row.id) })


          // }
        }
        }
        // scroll={{ x: 1200 }}
      >

        </Table>
        <h3>Total Users are: {count}</h3>
        <h3>Selected Users are: {selectedRowsData.length>0?selectedRowsData.length:count}</h3>

        </Form>
      </Modal>
      </div>
    );
  },
);

export default ScheduleView;
