import React from 'react';
import './index.scss';
import { Tooltip } from 'antd';

const getPropogatedParentNode = (node, goalLevel, currentLevel = 0) => {
  if (goalLevel > currentLevel) {
    return getPropogatedParentNode(node.parentNode, goalLevel, currentLevel + 1);
  }
  return node.parentNode;
};

function TooltipComp(props) {
  const {
    children, defaultRender = false, propogationLevel = 0, className,
  } = props;

  return (
    <Tooltip
      getPopupContainer={(triggerNode) => {
        if (defaultRender) {
          return document.body;
        }
        return getPropogatedParentNode(triggerNode, propogationLevel, 0);
      }}
      overlayClassName={`tooltip_Component ${className}-tooltip`}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
export default TooltipComp;
