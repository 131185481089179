import React from 'react';
import { createComponent } from 'effector-react';
import { withRouter } from 'react-router-dom';

import useMenuItems from '../../helpers/useMenuItems';
import useResizer from '../../helpers/resizer';
import {
  $currentUser,
  setCurrentUser,
  setMobileModalIsOpen,
} from '../../models';
import {
  $decodedToken,
  $accessExp,
  signOut,
  setAccount
} from '../../models/auth';

import Header from './Header';

const HeaderContainer = createComponent(
  {
    $currentUser,
    setCurrentUser,
    $accessExp,
    $decodedToken,
    setMobileModalIsOpen,
    setAccount
  },
  (props, state) => {
    const isMobile = useResizer();
    const menuItems = useMenuItems(state.$currentUser);

    return (
      <Header
        {...props}
        decodedToken={state.$decodedToken}
        profile={state.$currentUser}
        isMobile={isMobile}
        accessExp={state.$accessExp}
        menuItems={menuItems}
        setCurrentUser={state.setCurrentUser}
        signOut={signOut}
        setMobileModalIsOpen={state.setMobileModalIsOpen}
        setAccount={state.setAccount}
      />
    );
  },
);

export default withRouter(HeaderContainer);
