import React from 'react';
import {Modal} from "antd";

const ActivateModal = ({props}) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    selectedAction,
    selectedUser,
  } = props;

  const isActivate = selectedAction === 'activate';
  return (
    <Modal
      title={isActivate ? 'Activate User' : 'Delete User'}
      visible={isModalVisible}
      okText='Confirm'
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>Please confirm {isActivate ? 'activating' : 'deleting'} blocked user:</p>
      <p><b>{selectedUser?.firstName}{' '}{selectedUser?.lastName}</b></p>
    </Modal>
  );
}

export default ActivateModal;