import React from 'react';
import classnames from 'classnames';

import { Tooltip } from '../../../../components';
import redMarkIcon from '../../../../assets/red-mark.svg';
import blueMarkIcon from '../../../../assets/blue-mark.svg';

import './UserNameCell.scss';

const IsInProgressMark = (props) => {
  const { isInProgress } = props;

  return (
    <>
      {isInProgress ? (
        <Tooltip
          placement="topLeft"
          arrowPointAtCenter
          title="User whose profile hasn't been verified."
          propogationLevel={3}
        >
          <img
            className="userNameCellMark"
            src={redMarkIcon}
            width="14px"
            height="14px"
            alt="icon"
          />
        </Tooltip>
      ) : null}
    </>
  );
};

const ConsentApprovalMark = (props) => {
  const { consentMissing  } = props;

  return (
    <>
      {consentMissing  ? (
        <Tooltip
          placement="topLeft"
          arrowPointAtCenter
          title="User who hasn't signed the consent."
          propogationLevel={3}
        >
          <img
            className="userNameCellMark"
            src={blueMarkIcon}
            width="14px"
            height="14px"
            alt="icon"
          />
        </Tooltip>
      ) : null}
    </>
  );
};

const UserNameCell = (props) => {
  const { record } = props;

  const {
    firstName, lastName, verification, consentMissing, checklistMissing, email
  } = record;

 
  const isInProgress = verification === 'in_progress';
  const isBlueMark = consentMissing || checklistMissing;
  const isNameMissing = !(firstName || lastName)

  return (
    <div className="userNameCell">
      <IsInProgressMark isInProgress={isInProgress} />
      <ConsentApprovalMark consentMissing={isBlueMark} />

      <span
        className={classnames({
          userNameCellText: true,
        })}
      >
        {isNameMissing ? email: `${firstName} ${lastName}`}
      </span>
    </div>
  );
};

export default UserNameCell;
