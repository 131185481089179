/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Layout, Row, Col } from 'antd';

import {
  Logo,
  Menu,
  Avatar,
  MobileMenu,
  AccountSwitcher,
} from '../../components';
import Timer from '../Timer';
import { getUserProfileById, editUserProfileById } from '../../state/user/api';
import { getAccounts } from '../../state/auth/api';
import { MobileMenuIcon } from '../../assets';

import './Header.scss';

const { Header } = Layout;

const HeaderComponent = ({
  decodedToken,
  accessExp,
  signOut,
  profile,
  history,
  isMobile,
  menuItems,
  setCurrentUser,
  setMobileModalIsOpen,
  setAccount,
}) => {
  const firstName = get(profile, 'firstName');
  const lastName = get(profile, 'lastName');

  const signOutAction = () => {
    signOut();
    history.push('/sign-in');
  };

  useEffect(() => {
    getAccounts().then((response) => {
      setAccount(response?.data?.results)
    })
  }, []);

  useEffect(() => {
    if (decodedToken !== null) {
      getUserProfileById(decodedToken?.user_id)
        .then((response) => {
          setCurrentUser(response.data);
        })
    }
  }, [decodedToken]);

  return (
    <Header className="Header">
      <Row style={{ width: '100%', height: '70px' }} justify="space-between">
        <Col xs={12} sm={11} md={12} lg={12}>
          <div className="Header_Logo-Container">
            <Logo />
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={0}>
          {decodedToken && accessExp && <Timer />}
        </Col>
        <Col className="Auth-Container" xs={12} sm={13} md={12} lg={12}>
          {decodedToken && profile?.account && isMobile && (
            <>
              <MobileMenuIcon
                className="hamburger-icon"
                onClick={() => setMobileModalIsOpen(true)}
              />
              <MobileMenu editUser={editUserProfileById} />
            </>
          )}
          {decodedToken && profile?.account && !isMobile && (
            <>
              <AccountSwitcher
                user={profile}
                editUser={editUserProfileById}
              />
              <Avatar
                firstName={firstName}
                lastName={lastName}
                signOut={signOutAction}
              />
            </>
          )}
        </Col>
      </Row>

      {decodedToken && !isMobile && (
        <Row className="Header_Nav-Menu">
          <Menu menuItems={menuItems.filter((mi) => !mi.isMobileOnly)} />
        </Row>
      )}
    </Header>
  );
};

export default HeaderComponent;
