import React from 'react';

import './index.scss';

const Maintenance = ({isLoginMaintenance}) => (
  <div className="forgotSuccess-Container maintenance">
      <div className="maintenance_text">
      <h3>Application is unavailable</h3>
        {
          isLoginMaintenance 
          ? <p>There is planned site maintenance this evening between approximately <b>7pm</b> and <b>9pm</b>. You will be unable to login or create an appointment during this time.</p>
          : <p>There are 2 possible reasons fo this: we are performing some <b>maintenance work</b>, or <b>your internet connection is down.</b></p>
        }
        {
          isLoginMaintenance 
          ? <p> We are sorry for the inconvenience, please try again after <b>9pm</b>. </p>
          : <p>If you have ensured that your connection is ok, please try to access the app again in couple of hours. </p>
        }
          
        <p />
      </div>
  </div>
);

export default Maintenance;