import React from 'react';
import './index.scss';
import { withRouter } from 'react-router-dom';

function AccountSwitcher({
  user,
}) {

  return (
    <div className="AccountSwitcher">
        <div className="AccountSwitcher-Container">
          <div>
            <p>Account: </p>
            <p className="AccountSwitcher_title">
              {' '}
              {user?.account?.title}
            </p>
          </div>
        </div>
    </div>
  );
}

export default withRouter(
  AccountSwitcher,
);
