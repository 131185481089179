import axios from 'axios';
import applyConverters from 'axios-case-converter';
import qs from 'query-string';
import axiosRetry from 'axios-retry';
import { notification } from 'antd';

import {
  $currentUser,
  setIsMaintenanceMode,
  setIsInternalServerError,
} from '../models';
import { $decodedToken, signOut, actionDateRefresh } from '../models/auth';

const endpoint = () => {
  let hostprefix = window.location.hostname.split('.')[0];
  switch (hostprefix) {

    case 'web-dev':
      return 'https://api-dev.cdp.shiftpartner.com';

    case 'web-qa':
      return 'https://api-qa.cdp.shiftpartner.com';

    case 'webmse-qa':
      return 'https://api-qa.cdp.shiftpartner.com';

    case 'webeput-qa':
      return 'https://api-qa.cdp.shiftpartner.com';

    case 'web':
      return 'https://api.cdp.shiftpartner.com';

    case 'webmse-dev':
      return 'https://api-dev.cdp.shiftpartner.com';

    case 'webeput-dev':
      return 'https://api-dev.cdp.shiftpartner.com';


    default:
      return 'http://localhost:8000';
  }
};

export const wsEndpoint = () => {
  let hostprefix = window.location.hostname.split('.')[0];
  switch (hostprefix) {

    case 'web-dev':
      return 'wss://api-dev.cdp.shiftpartner.com';

    case 'web-qa':
      return 'wss://api-qa.cdp.shiftpartner.com';

    case 'webmse-qa':
      return 'https://api-qa.cdp.shiftpartner.com';

    case 'webeput-qa':
      return 'https://api-qa.cdp.shiftpartner.com';

    case 'web':
      return 'wss://api.cdp.shiftpartner.com';

    case 'webmse-dev':
      return 'https://api-dev.cdp.shiftpartner.com';

    case 'webeput-dev':
      return 'https://api-dev.cdp.shiftpartner.com';


    default:
      return 'wss://localhost:8000';
  }
};


let time = true;

const c = axios.create({
  baseURL: endpoint(),
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
});

axiosRetry(c, { retries: 3 });

const authHeaderRestrictions = [
  '/token/refresh/',
  '/accounts/'
]

const calendarUrl = '/api/v1/calendar/'
const notAllowedSystemMessage = 'User is not allowed to access our system'

const endpointsCheck = (url, restrictions) => !restrictions.some((restriction) => url.indexOf(restriction) > -1)

c.interceptors.request.use((config) => {
  const token = localStorage.getItem('access-token-v1');
  if (token !== null && endpointsCheck(config.url, authHeaderRestrictions)) {
    config.headers.Authorization = `JWT ${token}`;
  }

  if (time && config.url !== '/api/v1/token/refresh/') {
    time = false;
    actionDateRefresh();
    setTimeout(() => {
      time = true;
    }, 1000);
  }
  const user = $currentUser.getState();
  const { account } = $currentUser.getState();
  const decodedToken = $decodedToken.getState();

  const { data, url } = config;

  if (account && decodedToken && config.method === 'post') {


    let newData = null;

    if (config.url.indexOf('/send-invite/') > -1) {
      newData = {
        ...data,
        account_id: account.id,
      };
    } else {
      newData = {
        ...data,
        account: account.id,
      };
    }

    return { ...config, newData };
  }

  if (url.includes(calendarUrl)) {
    return { ...config, params: { ...config.params, user: user.id } };
  }

  return config;
});

c.interceptors.response.use(
  (data) => data,
  (e) => {
    if (e && e.message === "Network Error") {
      signOut();
      setIsMaintenanceMode(true);
    }
    if (e?.response?.status === 401) {
      signOut();
    }
    if (e?.response?.status === 403 && e?.response?.data?.detail?.includes(notAllowedSystemMessage)) {

      notification.error({ message: notAllowedSystemMessage })
      signOut();
    }

    if (e?.response?.status === 503 || e?.response?.status === 504) {
      setIsInternalServerError(true);
    }
    throw e;
  },
);

export const client = applyConverters(c);
