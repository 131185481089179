import React, {useCallback} from 'react';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

import './BlockedActionsCell.scss';
import {Tooltip} from "../../../../components";

const BlockedActionsCell = (props) => {
  const { record, setModalVisible, setSelectedUser, setSelectedAction } = props;

  const onClick = useCallback((event) => {
    event.stopPropagation();
    setSelectedUser(record);
    setSelectedAction('activate');
    setModalVisible(true);
  }, [record]);

  const onClickDelete = useCallback((event) => {
    event.stopPropagation();
    setSelectedUser(record);
    setSelectedAction('delete');
    setModalVisible(true);
  }, []);

  return (
    <div className="usersBlockedActionsCell">
    <Tooltip placement="top" title="Active">
      <div className="actionButton activate" onClick={onClick}>
        <CheckOutlined />
      </div>
    </Tooltip>
    <Tooltip placement="top" title="Delete">
      <div className="actionButton delete" onClick={onClickDelete}>
        <CloseOutlined />
      </div>
    </Tooltip>
    </div>
  );
};

export default BlockedActionsCell;
