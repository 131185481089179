import React from 'react';

import './index.scss';
import { InfoCard } from '../../../components';

const internalServer = () => (
  <div className="forgotSuccess-Container">
    <InfoCard
      title="Sorry, something went wrong..."
      message="Try to reload this page."
      reloadButton
    />
  </div>
);

export default internalServer;
