import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';

import { $decodedToken } from '../../models/auth';
import { ComboLogo, WessexLogo, CommisceoLogo,logo,ShiftLogo,NHSLogo} from '../../assets';
import { MultiTenancyHoc } from '../../helpers';
import { accountTypes } from '../../helpers/constants';
// import {eput} from '../../assets';
import './index.scss';

const Logo = () => {
  const decodedToken = useStore($decodedToken);
  const url = decodedToken ? '/appointments' : '/sign-in';

  return (
    <Link to={url} className="Logo-Container">
      <MultiTenancyHoc allowedRegions={[accountTypes.norfolk, accountTypes.midessex]}>
        <ComboLogo />
      </MultiTenancyHoc>
      <MultiTenancyHoc allowedRegions={[accountTypes.wessex]}>
        <WessexLogo style={{ paddingBottom: '5px', marginLeft: '-16px' }} />
      </MultiTenancyHoc>
      <MultiTenancyHoc allowedRegions={[accountTypes.commisceo]}>
        <CommisceoLogo style={{ paddingBottom: '5px' }}/>
      </MultiTenancyHoc>
      <MultiTenancyHoc allowedRegions={[accountTypes.EPUT]}>
      <ShiftLogo className="Logo1-Container"/>
      {<h3 className="Logo2-Container" style={{color:'white' }}>Essex Partnership University</h3>}
      {/* {<h3 style={{ paddingTop: '16px',marginLeft: '-136px',color:'white' }}>NHS Foundation Trust</h3>} */}
      {/* <NHSLogo style={{ paddingBottom: '5px',marginLeft: '-146px' }}/> */}
        {/* <img src={"https://eput.nhs.uk/media/cwofspcm/nhs-essex-trust-logo.jpg"} height={53} width={100} style={{ paddingBottom: '5px', marginLeft: '-136px' }}/> */}

      </MultiTenancyHoc>
    </Link>
  );
};

export default Logo;
