/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';
import moment from 'moment';

import { refreshToken } from '../../state/auth/api';
import { useMountedRef } from '../../helpers';

import './index.scss';

const calculateTimeLeft = (endTime) => moment(endTime).diff(moment());

function TimerComponent({
  accessExp,
  sessionRefresh,
  history,
  signOut,
  accessGained,
  resetCurrentUserEvent,
}) {
  const [timeLeft, setTimeLeft] = useState({});

  const isMounted = useMountedRef();

  useMemo(() => {
    if (accessExp) {
      setTimeLeft(calculateTimeLeft(accessExp));
    }
  }, [accessExp]);

  useMemo(() => {
    if (timeLeft <= 3000) {
      const lastActionDate = localStorage.getItem('lastActionDate');

      if (
        moment(Number(lastActionDate)).diff(moment(accessGained + 2000)) > 0
      ) {
        const fetchData = async () => {
          await refreshToken({
            refresh: localStorage.getItem('refresh-token-v1'),
          }).then((res) => {
            sessionRefresh(res);
          });
        };
        fetchData();
        return;
      }
      resetCurrentUserEvent();
      signOut();
      notification.success({
        message: 'Current session time is out. Please log in again.',
        duration: 5,
      });
      history.push('/sign-in');

    }

  }, [timeLeft]);

  useMemo(() => {
    if (timeLeft > 3000) {
      // for timer testimg
      // console.log('Time left calc', calculateTimeLeft(accessExp));
      // console.log('timeLeft', timeLeft)
      const timer = setInterval(() => {
        if (isMounted.current) {
          setTimeLeft(calculateTimeLeft(accessExp));
        }

      }, timeLeft);

      return () => clearInterval(timer);
    }
  }, [timeLeft])
  return <div className="Timer-Container" />;
}

export default withRouter(TimerComponent);
