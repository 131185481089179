import React from 'react';
import { createComponent } from 'effector-react';

import useMenuItems from '../../helpers/useMenuItems';
import useResizer from '../../helpers/resizer';
import {
  $currentUser,
  resetCurrentUser,
} from '../../models';
import {
  $decodedToken,
  $refreshExp,
  $accessExp,
  $accessGained,
  $lastActionDate,
  signOut,
  sessionRefresh
} from '../../models/auth';

import Timer from './Timer';

const TimerContainer = createComponent(
  {
    $currentUser,
    $decodedToken,
    $refreshExp,
    $accessExp,
    $accessGained,
    $lastActionDate,

    signOut,
    resetCurrentUser,
    sessionRefresh
  },
  (props, state) => {
    const isMobile = useResizer();
    const menuItems = useMenuItems(state.$currentUser);

    return (
      <Timer
        {...props}
        decodedToken={state.$decodedToken}
        isMobile={isMobile}
        menuItems={menuItems}
        setCurrentUser={state.setCurrentUser}
        profile={state.$currentUser}
        signOut={signOut}

        refreshExp={state.$refreshExp}
        accessExp={state.$accessExp}
        accessGained={state.$accessGained}
        lastActionDate={state.$lastActionDate}
        resetCurrentUserEvent={state.resetCurrentUser}
        sessionRefresh={sessionRefresh}
      />
    );
  },
);

export default TimerContainer;
