import React from "react";

const BlurredBackgroundWithMessage = ({tableRect}) => {
    return (
        <div style={{
            width: tableRect.width,
            height: tableRect.height,
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <p style={{
                color: '#96B0D7',
                fontSize: '18px',
                padding: '0 25px',
            }}>No appointments available at this time please come back later and try again.</p>
        </div>
    )
}

export default BlurredBackgroundWithMessage;