/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import classname from 'classnames';
import throttle from 'lodash/throttle';
import './index.scss';

// this function calculates space between container's bottom
// part and browser window bottom one
const calcSpace = (containerRef) => {
  const viewportOffset = containerRef?.current?.getBoundingClientRect();
  const bottom = viewportOffset?.bottom;
  const win = window;
  const doc = document;
  const docElem = doc.documentElement;
  const body = doc.getElementsByTagName('body')[0];
  const y = win.innerHeight || docElem.clientHeight || body.clientHeight;

  if(bottom === null){
    return 0
  }

  return y - bottom;
};

const StickyContainer = ({ children, vaccinationPart, selectedSlot }) => {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef(null);
  const viewportOffset = ref?.current?.getBoundingClientRect?.();
  const body = document.getElementsByTagName('body')[0];

  const toggle = throttle(() => {
    if (Math.ceil(calcSpace(ref)) === 0) {
      setIsSticky(true);
    }
    if (Math.ceil(calcSpace(ref)) > 0) {
      setIsSticky(false);
    }
  }, 100);

  useEffect(() => {
    toggle();
  }, [viewportOffset]);

  useEffect(() => {
    body.addEventListener('scroll', toggle);
    return () => body.removeEventListener('scroll', toggle);
  }, []);

  return (
    <div className="stickyContainer">
      {vaccinationPart && selectedSlot && (
      <div className={classname({
        vaccinationPartShadowBox: !isSticky,
        vaccinationPartShadowBoxSticky: isSticky,
      })}
      />
      ) }
      {isSticky && (
      <div className={classname('shadowBox', {
        vaccinationPart,
      })}
      />
      )}

      <div className={classname({ isSticky, child: true })} ref={ref}>
        {children}
      </div>
    </div>

  );
};

export default StickyContainer;
