/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { createComponent } from 'effector-react';
import './index.scss';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

import {
  $currentUser,
  $mobileModalIsOpen,
  setMobileModalIsOpen as setMobileModalIsOpenEvent,
} from '../../models';
import {
  signOut as signOutAction,
} from '../../models/auth';
import Logo from '../Logo';
import AccountSwitcher from '../AccountSwitcher';
import { Close } from '../../assets';
import useMenuItems from '../../helpers/useMenuItems';

const MobileMenu = (props) => {
  const {
    profile,
    signOut,
    editUser,
    mobileModalIsOpen,
    setMobileModalIsOpen,
  } = props;

  const menuItems = useMenuItems(profile);
  const handleLogOut = () => {
    setMobileModalIsOpen(false);
    signOut();
  };

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      setMobileModalIsOpen(false);
    };
    window.addEventListener('popstate', onBackButtonEvent, false);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent, false);
    };
  }, []);

  return (
    <Modal
      className="mobile-modal-menu"
      visible={mobileModalIsOpen}
      title={false}
      footer={false}
      onCancel={() => setMobileModalIsOpen(false)}
    >
      <div className="user-dropdown">
        <div className="user-dropdown_header">
          <div className="user-dropdown_header_logo">
            <div className="user-dropdown_header_logo_container">
              <Logo />
            </div>
            <div onClick={() => setMobileModalIsOpen(false)}>
              <Close />
            </div>
          </div>
          <div className="user-dropdown_header_info">
            <div className="user-dropdown_header_info_wrapper">
              <span className="user-dropdown_header_info_wrapper_name">
                {profile.firstName}
                {' '}
                {profile.lastName}
              </span>
              <span className="user-dropdown_header_info_wrapper_email">
                {profile.email}
              </span>
              <AccountSwitcher
                user={profile}
                editUser={editUser}
              />
            </div>
          </div>
        </div>

        <div className="user-dropdown_body">
          <Link
            to="/profile"
            onClick={() => setMobileModalIsOpen(false)}
            className="user-dropdown_body_item"
          >
            <p>User Profile</p>
          </Link>
          {menuItems.map((item) => (
            <Link
              to={item.to}
              onClick={() => setMobileModalIsOpen(false)}
              className="user-dropdown_body_item"
              key={item.title}
            >
              <p>{item.title}</p>
            </Link>
          ))}
        </div>
      </div>
      <div
        onClick={handleLogOut}
        className="user-dropdown_logout user-dropdown_body_item"
      >
        <p>Log out</p>
      </div>
    </Modal>
  );
};

const MobileMenuContainer = createComponent(
  {
    $currentUser,
    $mobileModalIsOpen,
    setMobileModalIsOpenEvent,
    signOutAction,
  },
  (props, state) => (
    <MobileMenu
      {...props}
      profile={state.$currentUser}
      mobileModalIsOpen={state.$mobileModalIsOpen}
      setMobileModalIsOpen={state.setMobileModalIsOpenEvent}
      signOut={state.signOutAction}
    />
  ),
);

export default MobileMenuContainer;
