import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const createOptions = (array) => array.map((item) => (
  <Option title={item.title} key={item.id} value={item.id}>
    {item.title}
  </Option>
));

export default createOptions;
