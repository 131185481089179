/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import './index.scss';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import classnames from 'classnames';
import { client } from '../../helpers/http';
import Tooltip from '../Tooltip';
import { Context } from '../../assets';
import { orderBy,get } from 'lodash';
import {
  synonymousUpcomingStatuses,
  uidAnalyses,
  procedureTypes,
  isCovidVaccineType,
  isBoostedVaccineType
} from '../../helpers/constants';
import {
  getCurrentAnalysesTitle
} from '../../helpers/utils';
import { setProcedureUid , setAppointment, $appointmentData } from "../../pages/Appointments/model";
import { setNextStep, possibleSteps } from "../../pages/Appointments/container/NewAppointment/components/Stepper/model";



const AppointmentCard = (props) => {
  const {
    onCancelAppointment, record, user,  firstColumn, analyses
    // isCommisceo,isWessex,
    // just removed above two from props.
  } = props;

  const appointmentData = useStore($appointmentData);
  const [data,setdata] = useState([]);
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');

  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const isAdHoc = record.adHoc;
  const isAptNow = record.aptNow;
  const isAntibodyTest = record.analyse.uid === uidAnalyses.antibody;
  const isСonsentApproval = user.consentApproval;
  const isBooked = Object.keys(synonymousUpcomingStatuses).includes(record.status)
    || record.status === 'id_verified';
  const isAddConsentLink = !isСonsentApproval && isAdHoc && isAntibodyTest;

  const isCovidVaccine = isCovidVaccineType(record?.analyse?.uid);
  const isBoostedVaccine = isBoostedVaccineType(record?.analyse?.uid);
  const isLaterally = record?.analyse?.uid === uidAnalyses.laterally;
  const isVaccine = record?.analyse?.procedureType === procedureTypes.vaccine;
  const isSwab = record?.analyse?.uid === uidAnalyses.swab;
  const notifiedCovidVaccine = isCovidVaccine && record?.status === 'subject_notified';
  const noAdverseEffects = record?.adverseEffects === false && notifiedCovidVaccine;
  const haveAdverseEffects = record?.adverseEffects && notifiedCovidVaccine;

  const isFillChecklist = !record?.checklist
    && (isVaccine)
    && isBooked
    && record?.vaccinationPart !== 2;

  const currentAnalysesTitle = getCurrentAnalysesTitle(analyses, record?.analyse?.uid)

  const [statusLabel, statusClass] = useMemo(() => {
    if (record.status === 'subject_notified' || record.status === 'patient_reported') {
      if (record.testResult === 'positive') return ['Positive', 'red'];
      if (record.testResult === 'negative') return ['Negative', 'green'];
      // if (record.testResult === 'indeterminate') return [isWessex ? 'Test Error' : 'Inconclusive', 'gray']; commented out by asif
      if (record.testResult === 'vaccinated') return ['Vaccinated', 'blue'];
      if (record.testResult === 'tested') return ['Tested', 'blue'];
      if (isLaterally) return ['Completed', 'blue'];
      if (record.testResult === 'invalid') return ['Invalid', 'gray'];
    }
    if (
      record.status === 'sample_taken'
      || record.status === 'results_received'
    ) {
      return ['Awaiting Results', 'yellow'];
    }
    if (isBooked) {
      return ['Booked', 'blue'];
    }

    return ['', 'blue'];
  }, [record.status]);

  const createTitleToShow = (record, currentTitle) => {
    const titles = {
      [true]: currentTitle,
      [Boolean(record?.vaccinationPart)] : `${record?.vaccinationPart} COVID Vaccination shot`,
    }
    return titles[true];
  }

  const inner = (
    <>
      <div className="appointmentCardWrapper">
        <div className="AppointmentCard_Info">
          <div className="AppointmentCard_Info_Details">
            <Tooltip placement="bottom" title={record.fullName}>
              <p className="AppointmentCard_Info_Details_Name">
                {record.fullName}

              </p>
            </Tooltip>

            <p className={classnames('AppointmentCard_Info_Details_Type', {
              [`vaccinationPart-${record?.vaccinationPart}`]: record?.vaccinationPart,
            })}
            >
              {createTitleToShow(record, currentAnalysesTitle)}
            </p>
            <p>{`${record?.startTime} on ${record?.bookingDate}`}</p>
             <Tooltip placement="bottom" title={`${record?.scheduler?.hospital?.title} ${record?.scheduler?.hospital?.archived ? '(Archived)' : ''}`}>
              <p className="AppointmentCard_Info_Details_Hospital">{record?.scheduler?.hospital?.title} {`${record?.scheduler?.hospital?.archived ? '(Archived)' : ''}`}</p>

            </Tooltip>
            {record.vaccinator &&(
            <p>Vaccinated By:<b>{ record?.vaccinator?.firstName?record?.vaccinator?.firstName +' ' + record.vaccinator?.lastName:' '}</b></p>
            )}
            {record.freeTextVaccinatedBy &&(
            <p>Vaccinated By:<b>{record?.freeTextVaccinatedBy?record.freeTextVaccinatedBy:' '}</b></p>
            )}
          </div>
          <div
            className={`AppointmentCard_Info_Status AppointmentCard_Info_Status--${statusClass}`}
          >
            <div className="AppointmentCard_Info_Button_Label">
              <div className="appointmentCardButtonLabel">{statusLabel}</div>
              {isAdHoc && firstColumn && (
                <div className="appointmentCardButtonSubLabel">by manager</div>
              )}
            </div>
            {Object.keys({ ...synonymousUpcomingStatuses }).includes(
              statusLabel.toLowerCase(),
            ) ? (
                <>
                  <Dropdown
                    placement="bottomRight"
                    onVisibleChange={(value) => {
                      setDropdownVisible(value);
                    }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    overlay={(
                      <Menu className="AppointmentCard_Options" mode="inline">
                        <Menu.Item>
                          <Link
                            to={{
                              pathname: `/appointments/${record.id}/confirmed`,
                            }}
                          >
                            View Appointment Details
                        </Link>
                        </Menu.Item>
                        {
                          // removed  || (isCommisceo && isSwab) condition from bellow line after isLatterally
                          !((isCovidVaccine && !isBoostedVaccine) || isLaterally) && (
                            <Menu.Item disabled={isAptNow}>
                              <div
                                onClick={() => {
                                  // if (!isAptNow && !isCommisceo) {
                                    setAppointment({
                                      ...appointmentData,
                                      id: record?.id,
                                      analyse: record?.analyse,
                                      user: record?.user,
                                    });
                                    history.push(
                                      `/appointments/${isVaccine ? 'schedule' : `edit/${record.id}`
                                      }`,
                                      {
                                        isAdHocRebook: record?.adHoc,
                                        isAdHoc: record?.adHoc,
                                        isAptNow: record?.aptNow,
                                        isAptNowRebook: record?.aptNow,
                                        rescheduleOrg: user?.organization,
                                      },
                                    );
                                  // } commented if condition
                                  if (isVaccine) {
                                    setNextStep(possibleSteps.schedule);
                                  } else {
                                    setNextStep(possibleSteps.symptoms);
                                  }
                                }}
                              >
                                Edit Appointment
                          </div>
                            </Menu.Item>
                          )
                        }

                        <Menu.Item
                          onClick={() => {
                            setDropdownVisible(false);
                            onCancelAppointment(record);
                          }}
                        >
                          <span>Cancel Appointment</span>
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Tooltip placement="top" title="Options">
                      <div
                        className={classnames({
                          AppointmentCard_Info_Button_Open: true,
                          hovered: dropdownVisible,
                        })}
                      >
                        <Context />
                      </div>
                    </Tooltip>
                  </Dropdown>
                </>
              ) : null}
          </div>
        </div>
        <hr className="AppointmentCard_Divider" />
        <div className="AppointmentCard_Confirmation">
          <span className="AppointmentCard_Confirmation_Text">
            Confirmation Code
          </span>
          <span className="AppointmentCard_Confirmation_Number">
            {record.confirmationNumber}
          </span>
        </div>
      </div>

      {isAddConsentLink && (
        <Link to={`/consent-approval/${user.id}`}>
          <div className="appointmentCardConsentBtn">Consent required</div>
        </Link>
      )}
      {isFillChecklist && (
        <div
          onClick={
            () => {
              history.push(`/consent-approval/${record.id}/vaccinating`, {
                fromMyTestsCard: true,
              })
              setProcedureUid(record?.analyse?.uid);
            }}
          className="appointmentCardConsentBtn"
        >
          Fill Checklist
        </div>
      )}
      {noAdverseEffects && (
        <div
          onClick={() => history.push(`/adverse-effects/${record.id}`)}
          className="appointmentCardConsentBtn appointmentCardConsentBtn_adverse"
        >
          Report Side Effects
        </div>
      )}
      {haveAdverseEffects && (
        <div
          onClick={() => history.push(`/adverse-effects/${record.id}`)}
          className="appointmentCardConsentBtn appointmentCardConsentBtn_adverse"
        >
          Edit Side Effects
        </div>
      )}
    </>
  );

  return (
    <div
      className={classnames('AppointmentCard', {
        appointmentCardNoBorder: isAddConsentLink,
      })}
    >
      {inner}
    </div>
  );
};

export default AppointmentCard;
