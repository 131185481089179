import { createStore, createEffect, createEvent } from 'effector';
import { notification } from 'antd';
import { snakeCase } from 'change-case';
import { get, find, orderBy } from 'lodash';
import { createDebounce } from 'effector-debounce';
import moment from 'moment';

import { client } from '../../helpers/http';
import {
  restrictedAppNowSteps,
  restrictedVaccineSteps,
  restrictedLaterallySteps,
  statuses,
  uidAnalyses,
  procedureTypes,
  isCovidVaccineType,
  isBoostedVaccineType
} from '../../helpers/constants';

import {
  getStatusData,
  getRenderDoB,
  getTimeline,
  prepareAppointment,
} from './helpers';

export const $batchLoading = createStore(false);
export const $adverseEffectsForPartOne = createStore(null);
export const $adverseEffectsForPartTwo = createStore(null);
export const $adverseEffectsStartDateForPartOne = createStore(null);
export const $adverseEffectsStartDateForPartTwo = createStore(null);
export const $isLoading = createStore(false);
export const $configs = createStore({});
export const $analyses = createStore({});
export const $appointment = createStore({});
export const $parentAppointment = createStore(null);
export const $childAppointment = createStore(null);
export const $restrictions = createStore({});
export const $isOpenDateInput = createStore(false);
export const $changedTime = createStore(null);
export const $changedDate = createStore(null);

export const $appointmentUser = createStore({});
export const $statusSteps = createStore([]);
export const $currentStatus = createStore(null);
export const $reasons = createStore([]);
export const $timeline = createStore([]);
export const $isStatusChanging = createStore(false);
export const $batchNumbers = createStore([]);
export const $multipleBatchNumbers = createStore({});

export const $selectedBatchNumber = createStore(null);
export const $selectedBatchNumberBoostedFlu = createStore(null);
export const $selectedBatchNumberBoostedCovid = createStore(null);

export const $procedureType = createStore('');

export const $batchSearch = createStore('');
export const $multipleBatchSearch = createStore({});
export const $maskTypes = createStore([]);

const defaultPagination = {
  total: null,
  current: 1,
  pageSize: 10000,
}
export const $batchPagination = createStore(null);
export const $multipleBatchPagination = createStore(null);

export const $noSymptoms = createStore(false);
export const $noVaccination = createStore(false);
export const $noSwabResult = createStore(false);
export const $noPregnant = createStore(false);

export const resetData = createEvent();
export const resetMultipleBatchNumbers = createEvent();
export const setAdverseEffectsForPartOne = createEvent();
export const setAdverseEffectsForPartTwo = createEvent();
export const setAdverseEffectsStartDateForPartOne = createEvent();
export const setAdverseEffectsStartDateForPartTwo = createEvent();
export const fetchAppointmentPageData = createEvent();
export const setAppointment = createEvent();
export const setAnalyses = createEvent();
export const setParentAppointment = createEvent();
export const setAppointmentUser = createEvent();
export const setStatusSteps = createEvent();
export const setCurrentStatus = createEvent();
export const setConfigs = createEvent();
export const setReasons = createEvent();
export const changeAppointmentData = createEvent();
export const changeUserData = createEvent();
export const handleVerificationRelationship = createEvent();
export const cancelAppointment = createEvent();
export const setTimeline = createEvent();
export const sendProcedureInfo = createEvent();

export const changeSelectedBatchNumber = createEvent();
export const changeSelectedBatchNumberBoostedFlu = createEvent();
export const changeSelectedBatchNumberBoostedCovid = createEvent();

export const setBatchNumbers = createEvent();
export const setMultipleBatchNumbers = createEvent();

export const clearBatchSearch = createEvent();
export const changeProcedureType = createEvent();
export const setChildAppointment = createEvent();
export const fetchRestrictions = createEvent();
export const changeTime = createEvent();
export const changeDate = createEvent();
export const submitChangeDateInputs = createEvent();

export const setNoSymptoms = createEvent();
export const setNoVaccination = createEvent();
export const setNoSwabResult = createEvent();
export const setNoPregnant = createEvent();
export const clearAllCancelChexboxes = createEvent();

export const changeBatchSearch = createEvent();
export const resetPagination = createEvent();

export const setBatchPagination = createEvent();
export const setMultipleBatchPagination = createEvent();

export const fetchMoreBatchNumbers = createEvent();
export const changeOpenDateInput = createEvent();
export const resetChangedInputs = createEvent();
export const fetchMaskTypes = createEvent();

const _fetchAppointment = createEffect();
const _changeAppointmentData = createEffect();
const _changeUserData = createEffect();
const _handleVerificationRelationship = createEffect();
const _cancelAppointment = createEffect();
const _fetchBatchNumbers = createEffect();
const _fetchRestrictions = createEffect();
const _submitChangeDateInputs = createEffect();
const _fetchMaskTypes = createEffect();

const changeBatchSearchDebounce = createDebounce(changeBatchSearch, 500);

$batchPagination
  .on(setBatchPagination, (_, pagination) => pagination)
  .reset(resetPagination);

$multipleBatchPagination
  .on(setMultipleBatchPagination, (_, pagination) => pagination)
  .reset(resetPagination);


_changeUserData.use(({ userId, number}) => client
    .patch(`/api/v1/users/${userId}/?management=true`, {
      number
    }));

_submitChangeDateInputs.use(({id}) => {
  const changedDate = $changedDate.getState();
  const changedTime = $changedTime.getState();
    return client
      .patch(`/api/v1/status_history/${id}/`, {
        customModified: moment(`${changedDate} ${changedTime}`).format(),
      })
      .then((response) => {
        notification.success({
          message: 'Successfully.',
        });
        const timeline = $timeline.getState();
        const updatedStatuses = get(response, 'data', []);
        setTimeline(timeline.map((statusItem) => {
          const findedItem = updatedStatuses.find((status) => status.id === statusItem.id);
          return findedItem ? {...findedItem, title: findedItem?.value === 'arrived' ? 'Patient arrived' : 'Sample taken'} : statusItem;
        }));
      })
      .catch((error) => {
        const message = get(error, 'response.data.detail', '');
        notification.error({ message });
      });
});


_fetchRestrictions.use(() => client
  .get('/api/v1/restrictions/', {
    params: {
      userId: $appointmentUser?.getState?.()?.id,
    },
  })
  .then((response) => get(response, 'data', [])));

const getProceduresForCombined = (analyses, procedure, type) =>{
  const currentAnalyse = analyses.find((analyse) => analyse.uid === procedure)
  if (!currentAnalyse.analyseComponents.length){
    return procedure
  }
  const analyseIds = currentAnalyse.analyseComponents.find((comp) => comp.type === type)?.components
  return analyseIds.map((analyseId)=> {
    return analyses?.find((analyse) => analyse.id === analyseId )?.uid
  })
}

_fetchMaskTypes.use(() => client.get('/api/v1/masktypes').then((respones) => get(response, 'data',[])));
const fetchBatchNumbers = async (data) => {
  const vaccineTypes = data?.types || [];
  const isNew = data?.new || false;

  try {
    const procedureIn = $procedureType.getState()

    let pagination = null;
    let search = null
    let numbers = []
    let stateNumbers = $batchNumbers.getState()

    let optionsData = []
    let response = null

    if(vaccineTypes.length){
      stateNumbers = $multipleBatchNumbers.getState()
    } else {
      stateNumbers = $batchNumbers.getState()
    }

    if(vaccineTypes.length){
      vaccineTypes.forEach((vaccinationType)=>{
        optionsData.push({
          vaccinationType,
          pagination: isNew ? defaultPagination :$multipleBatchPagination.getState()?.[vaccinationType] || defaultPagination,
          search: $multipleBatchSearch.getState()?.[vaccinationType] || '',
          numbers: $multipleBatchNumbers.getState()?.[vaccinationType] || [],
        })
      })
      pagination = $multipleBatchPagination.getState();
    } else {
      pagination = isNew ? defaultPagination : $batchPagination.getState() || defaultPagination;
      search = $batchSearch.getState();
    }

    if(optionsData.length > 0){
      const analyses = $analyses.getState()
      response = await Promise.all(optionsData.map(
        (data) => {
          const procedure = getProceduresForCombined(analyses, procedureIn, data?.vaccinationType)
          const options = {
            params: {
              ordering: 'title',
              offset: (data.pagination.current - 1) * data.pagination.pageSize,
              limit: data.pagination.pageSize,
              archived: false,
              search,
              procedureIn: procedure,
            },
          };

          return client.get('/api/v1/codes/', options)
            .then((response) => ({response, vaccinationType: data.vaccinationType}))
        })
      )
    } else {
      const options = {
        params: {
          ordering: 'title',
          offset: (pagination.current - 1) * pagination.pageSize,
          limit: pagination.pageSize,
          archived: false,
          search,
          procedureIn,
          brand: $appointment?.getState?.()?.parent?.batchNumber?.brand?.id,
        },
      };

      response = await client
        .get('/api/v1/codes/', options)
        .then((resp) => resp)
        .catch((error) => {
          const message = get(error, 'message', '');
          notification.error({ message });
        });
    }

    if(response?.length){
      const multipleBatchPaginationData = {}
      response.forEach((data, index)=> {
        multipleBatchPaginationData[data.vaccinationType] = {
          current: optionsData[index].pagination.current + 1,
          total: optionsData[index].pagination.total + get(data.response, 'data.results', []).length,
          count: get(data.response, 'data.count', 0),
          pageSize: 30
        }
      })
      setMultipleBatchPagination(isNew ? multipleBatchPaginationData : {...pagination, ...multipleBatchPaginationData});
    } else {
      setBatchPagination({
        current: pagination.current + 1,
        total: pagination.total + get(response, 'data.results', []).length,
        count: get(response, 'data.count', 0),
        pageSize: 30
      });
    }

    if(response?.length){
      const numbersData =  stateNumbers
      response.map((data)=> {
        numbersData[data.vaccinationType] = [
          ...isNew ? [] : numbersData?.[data.vaccinationType] || [],
          ...get(data.response, 'data.results', [])
        ]
      })
      numbers = numbersData
    } else {
      numbers = isNew ? get(response, 'data.results', []) : [...(stateNumbers || []), ...get(response, 'data.results', [])]
    }


    if(response?.length){
      setMultipleBatchNumbers(numbers);
    } else {
      setBatchNumbers(numbers);
    }

  } catch (error) {
    console.log('appt error' , error);
  }

};

_fetchBatchNumbers.use(async (data) => {
  await fetchBatchNumbers(data);
});

const fetchUserById = (userId) => client
  .get(`/api/v1/users/${userId}/?management=true`)
  .then((response) => {
    const appointmentUser = get(response, 'data', {});
    appointmentUser.renderDoB = getRenderDoB(appointmentUser.dateOfBirth);
    setAppointmentUser(appointmentUser);
  });

_fetchAppointment.use((appointmentId) => Promise.all([
  client.get(`/api/v1/appointments/${appointmentId}/?management=true`),
  client.get('/api/v1/configs/'),
  client.get('/api/v1/analyses/'),
])
  .then(async (responses) => {
    const [appointmentRes, configsRes, analysesRes] = responses;

    const appointment = get(appointmentRes, 'data', {});
    const isPartTwo = get(appointment, 'vaccinationPart', false) === 2;
    const aptChild = get(appointment, 'childSet', {})?.[0]?.id;

    if (aptChild) {
      const { data } = await client.get(`/api/v1/appointments/${aptChild}/?management=true`);
      setChildAppointment(data);
    }

    const configs = get(configsRes, 'data', {});
    const analyses = get(analysesRes, 'data.results', {});
    const userId = get(appointmentRes, 'data.user', {});
    const { statusList, currentStatus } = getStatusData(appointment);

    const isCovidVaccine = isCovidVaccineType(appointment?.analyse?.uid);
    const isVaccine = appointment?.analyse?.procedureType === procedureTypes.vaccine;

    if (isCovidVaccine) {
      if (isPartTwo) {
        const adverseEffectsForPartTwo = get(appointment, 'adverseEffects', null);
        const adverseEffectsStartDateForPartTwo = get(appointment, 'effectsStartDate', null);
        setAdverseEffectsForPartTwo(adverseEffectsForPartTwo);
        setAdverseEffectsStartDateForPartTwo(adverseEffectsStartDateForPartTwo ? moment(adverseEffectsStartDateForPartTwo).format('DD MMM YYYY') : null);

        const adverseEffectsForPartOne = get(appointment, 'parent.adverseEffects', null);
        const adverseEffectsStartDateForPartOne = get(appointment, 'parent.effectsStartDate', null);
        setAdverseEffectsForPartOne(adverseEffectsForPartOne);
        setAdverseEffectsStartDateForPartOne(adverseEffectsStartDateForPartOne ? moment(adverseEffectsStartDateForPartOne).format('DD MMM YYYY') : null);
      } else {
        const adverseEffectsForPartOne = get(appointment, 'adverseEffects', null);
        const adverseEffectsStartDateForPartOne = get(appointment, 'effectsStartDate', null);
        setAdverseEffectsForPartOne(adverseEffectsForPartOne);
        setAdverseEffectsStartDateForPartOne(adverseEffectsStartDateForPartOne ? moment(adverseEffectsStartDateForPartOne).format('DD MMM YYYY') : null);
      }
    }

    const preparedAppointment = prepareAppointment(appointment, configs, currentStatus);
    const { statusChoices } = configs;

    let timeline = getTimeline(preparedAppointment, currentStatus, statusChoices);

    if (appointment?.aptNow) {
      timeline = timeline.filter((el) => !restrictedAppNowSteps?.includes?.(el.status));
    } else if (isVaccine) {
      timeline = timeline.filter((el) => !restrictedVaccineSteps?.includes?.(el.status));
    } else if (appointment?.analyse?.uid === uidAnalyses.laterally) {
      timeline = timeline.filter((el) => !restrictedLaterallySteps?.includes?.(el.status));
    }

    setAnalyses(analyses)
    setAppointment(preparedAppointment);
    setStatusSteps(statusList);
    setCurrentStatus(currentStatus);
    setConfigs(configs);
    setTimeline(timeline);

    const reasonsData = configs.appointmentReasonConfig;
    const orderedReasons = orderBy(reasonsData, [(reason) => reason.value.toLowerCase()], ['asc']);
    setReasons(orderedReasons);

    return userId;
  })
  .then(fetchUserById)
  .catch((error) => {
    const message = get(error, 'message', '');
    notification.error({ message });
  }));

const onStatusChangeRequest = ((data) => client
  .patch(`/api/v1/appointments/${data.id}/?management=true`, data)
  .then((response) => {
    const appointment = get(response, 'data', {});
    const { currentStatus } = getStatusData(appointment);
    const configs = $configs.getState();

    const preparedAppointment = prepareAppointment(appointment, configs, currentStatus);
    let timeline = getTimeline(preparedAppointment, currentStatus, configs.statusChoices);

    const isVaccine = appointment?.analyse?.procedureType === procedureTypes.vaccine;

    if (appointment?.aptNow) {
      timeline = timeline.filter((el) => !restrictedAppNowSteps?.includes?.(el.status));
    } else if (isVaccine) {
      timeline = timeline.filter((el) => !restrictedVaccineSteps?.includes?.(el.status));
    } else if (appointment?.analyse?.uid === uidAnalyses.laterally) {
      timeline = timeline.filter((el) => !restrictedLaterallySteps?.includes?.(el.status));
    }

    setTimeline(timeline);
    setAppointment(preparedAppointment);
    setCurrentStatus(currentStatus);
  })
  .then((resp) => {
    // TODO: fix it on back-end
    const { next_statuses: nextStatuses } = data;
    const appointment = $appointment.getState();

    const isCovidVaccine = isCovidVaccineType(appointment?.analyse?.uid);
    if (nextStatuses?.includes?.(statuses.subjectNotified) && isCovidVaccine && appointment?.vaccinationPart === 1) {
      fetchRestrictions();
    }
  }).catch((error) => {
    const message = get(error, 'message', '');
    notification.error({ message: error?.response?.data?.errors || error?.response?.data?.swabId || message });
    throw Error;
  }));

export const onStatusChange = createEffect(onStatusChangeRequest);

sendProcedureInfo.watch((data) => {
  return client.post(`/api/v1/procedure_metadata/`, data)
});

_cancelAppointment.use((data) => {
  const appointment = $appointment.getState();
  const { id, vaccinationPart } = appointment;
  const { isBlocked, selectedReason, isManagement, isDidNotAttend } = data;
  const noSymptoms = $noSymptoms.getState();
  const noVaccination = $noVaccination.getState();
  const noSwabResult = $noSwabResult.getState();
  const noPregnant = $noPregnant.getState();

  const additionalData = vaccinationPart ? {
    statement: noSymptoms,
    noVaccines: noVaccination,
    noSwab: noSwabResult,
    notPregnant: noPregnant,
  } : {};

  const body = isDidNotAttend
    ? {
      status: "did_not_attend"
    } : {
      status: isBlocked ? 'cancelled_blocked' : 'cancelled',
      reason: selectedReason,
      ...additionalData,
    };

  return client
    .patch(`/api/v1/appointments/${id}/${isManagement ? '?management=true' : ''}`, body);
});

export const changeAppointmentDataByPatient = (data) => {
  const {
    id, selectedType
  } = data;
  return client
    .patch(`/api/v1/appointments/${id}/`, {
      selectedType
    })
}

_changeAppointmentData.use((appointmentData) => {
  const {
    id, number, firstName, lastName, dateOfBirth, phoneNumber, selectedType, vaccinationPartTwoDate
  } = appointmentData;

  return client
    .patch(`/api/v1/appointments/${id}/?management=true`, {
      number,
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      selectedType,
      vaccinationPartTwoDate
    })
    .then(() => {
      const appointment = $appointment.getState();
      fetchAppointmentPageData(appointment.id);
    });
});

_handleVerificationRelationship.use((appointmentData) => {
  const { id, verification } = appointmentData;

  return client
    .patch(`/api/v1/appointments/${id}/?management=true`, { verification })
    .then(() => {
      const appointment = $appointment.getState();
      fetchAppointmentPageData(appointment.id);
    });
});

$adverseEffectsForPartOne.on(setAdverseEffectsForPartOne, (_, params) => params).reset(resetData);
$adverseEffectsForPartTwo.on(setAdverseEffectsForPartTwo, (_, params) => params).reset(resetData);
$adverseEffectsStartDateForPartOne
  .on(setAdverseEffectsStartDateForPartOne, (_, params) => params)
  .reset(resetData);

$adverseEffectsStartDateForPartTwo
  .on(setAdverseEffectsStartDateForPartTwo, (_, params) => params)
  .reset(resetData);

$appointment.on(setAppointment, (_, params) => params).reset(resetData);
$parentAppointment.on(setParentAppointment, (_, params) => params).reset(resetData);
$childAppointment.on(setChildAppointment, (_, params) => params).reset(resetData);
$appointmentUser.on(setAppointmentUser, (_, params) => params).reset(resetData);
$statusSteps.on(setStatusSteps, (_, params) => params).reset(resetData);
$currentStatus.on(setCurrentStatus, (_, params) => params).reset(resetData);
$configs.on(setConfigs, (_, params) => params);
$analyses.on(setAnalyses, (_, params) => params);
$reasons.on(setReasons, (_, params) => params).reset(resetData);
$timeline.on(setTimeline, (_, params) => params).reset(resetData);

$batchNumbers
  .on(setBatchNumbers, (_, params) => params).reset(resetData)
  .on(setAppointment, (_, appointment) => {
    const isBoostedVaccine = isBoostedVaccineType(appointment.analyse.uid)
    if(!isBoostedVaccine) {
      return _fetchBatchNumbers({appointment})
    }

  });

$multipleBatchNumbers
  .on(setMultipleBatchNumbers, (_, params) => params)
  .on(setAppointment, (_, appointment) => {
    const isBoostedVaccine = isBoostedVaccineType(appointment.analyse.uid)
    if(isBoostedVaccine) {
      changeSelectedBatchNumberBoostedCovid(null)
      resetMultipleBatchNumbers()
      changeSelectedBatchNumberBoostedFlu(null)
      changeSelectedBatchNumberBoostedCovid(null)
      return _fetchBatchNumbers({appointment, types: ['covid', 'flu'] }, )
    }
  })
  .reset(resetData)
  .on(resetMultipleBatchNumbers, (_, params) => {
    return {}
  });

$selectedBatchNumber
  .on(changeSelectedBatchNumber, (_, params) => params)
  .on(setBatchNumbers, () => {
    if (!$appointment?.getState?.()?.parent) {
      return null;
    }
  })
  .on(setAppointment, (_, params) => params?.parent?.batchNumber?.id);

$selectedBatchNumberBoostedFlu
  .on(changeSelectedBatchNumberBoostedFlu, (_, params) => params)

$selectedBatchNumberBoostedCovid
  .on(changeSelectedBatchNumberBoostedCovid, (_, params) => params)
  .on(setBatchNumbers, () => {
    if (!$appointment?.getState?.()?.parent) {
      return null;
    }
  })
  .on(setAppointment, (_, params) => params?.parent?.batchNumber?.id);

$procedureType.on(setAppointment, (_, params) => params?.analyse?.uid);
$restrictions
  .on(setAppointmentUser, (_, params) => _fetchRestrictions(params?.id))
  .on(_fetchRestrictions.done, (_, { result }) => result);
$noSymptoms.on(setNoSymptoms, (_, params) => params).reset(clearAllCancelChexboxes);
$noVaccination.on(setNoVaccination, (_, params) => params).reset(clearAllCancelChexboxes);
$noSwabResult.on(setNoSwabResult, (_, params) => params).reset(clearAllCancelChexboxes);
$noPregnant.on(setNoPregnant, (_, params) => params).reset(clearAllCancelChexboxes);
$isOpenDateInput.on(changeOpenDateInput, (_, params) => params);
$changedTime
  .on(changeTime, (_, params) => params)
  .reset(resetChangedInputs);
$changedDate
  .on(changeDate, (_, params) => params)
  .reset(resetChangedInputs);

fetchAppointmentPageData.watch((appointmentId) => _fetchAppointment(appointmentId));
cancelAppointment.watch((data) => _cancelAppointment(data));
fetchRestrictions.watch(() => _fetchRestrictions());
changeAppointmentData.watch((appointmentData) => _changeAppointmentData(appointmentData));
changeUserData.watch((data) => _changeUserData(data));
handleVerificationRelationship
  .watch((appointmentData) => _handleVerificationRelationship(appointmentData));
submitChangeDateInputs.watch((data) => _submitChangeDateInputs(data));


changeBatchSearchDebounce.watch((data) => _fetchBatchNumbers(data));
fetchMoreBatchNumbers.watch((data) => _fetchBatchNumbers(data));

$maskTypes.on(_fetchMaskTypes.done, (_, { result }) => result);
fetchMaskTypes.watch(() => _fetchMaskTypes());

$isLoading
  .on(_fetchAppointment, () => true)
  .on(_fetchAppointment.done, () => false)
  .on(_fetchAppointment.fail, () => false)
  .on(_changeAppointmentData, () => true)
  .on(_changeAppointmentData.done, () => false)
  .on(_changeAppointmentData.fail, () => false)
  .on(_changeUserData, () => true)
  .on(_changeUserData.done, () => false)
  .on(_changeUserData.fail, () => false)
  .on(_handleVerificationRelationship, () => true)
  .on(_handleVerificationRelationship.done, () => false)
  .on(_handleVerificationRelationship.fail, () => false)
  .on(_fetchRestrictions, () => true)
  .on(_fetchRestrictions.done, () => false)
  .on(_fetchRestrictions.fail, () => false)
  .on(_submitChangeDateInputs, () => true)
  .on(_submitChangeDateInputs.done, () => false)
  .on(_submitChangeDateInputs.fail, () => false);

$isStatusChanging
  .on(onStatusChange, () => true)
  .on(onStatusChange.done, () => false)
  .on(onStatusChange.fail, () => false);

$batchSearch.on(changeBatchSearch, (_, params) => params).reset(clearBatchSearch);
$batchLoading
  .on(_fetchBatchNumbers, () => true)
  .on(_fetchBatchNumbers.done, () => false);
