import { createStore, createEffect, createEvent } from 'effector';
import { createDebounce } from 'effector-debounce';
import get from 'lodash/get';
import { notification } from 'antd';

import { client } from '../../helpers/http';
import {$appointmentUser} from "../AppointmentDetails/model";
import { $currentUser } from '../../models';

export const $loading = createStore(false);
export const $locations = createStore([]);
export const $isReasonModalVisible = createStore(false);
export const $appointmentData = createStore(null);
export const $appointmentParent = createStore(null);
export const $isSent = createStore(false);
export const $search = createStore('');
export const $isElective = createStore(false);

// adding two stores for users
export const $status = createStore([]);
export const $userSearch = createStore('');

export const $pagination = createStore({
  total: null,
  current: 1,
  pageSize: 20,
});

//adding users pagination
export const $usersPagination = createStore({
  total: null,
  current: 1,
  pageSize: 20,
});

// end

export const $hospitalsPagination = createStore({
  total: null,
  current: 1,
  pageSize: 20,
  count: null,
});
export const $hospitals = createStore([]);
//
export const $users = createStore([]);
export const $hospitalLoading = createStore(false);
export const $procedureUid = createStore(null);
export const $reasons = createStore([]);
export const $configs = createStore({});
export const $isLoading = createStore(false);

export const fetchLocations = createEvent();
//
export const fetchUsers = createEvent();
export const changeStatus = createEvent();
export const changeUsersSearch = createEvent();
export const changeUsersPagination = createEvent();
export const resetUsers = createEvent();
//
export const setDefaultAppointment = createEvent();
export const sentConfirmationEmail = createEvent();
export const toggleReasonModal = createEvent();
export const setAppointment = createEvent();
export const setIsSentDefault = createEvent();
export const changeSearch = createEvent();
export const clearSearch = createEvent();
export const setPagination = createEvent();
export const setProcedureUid = createEvent();
export const setAppointmentParent = createEvent();
export const resetProcedureUid = createEvent();
export const resetAppointmentParent = createEvent();
export const fetchHospitals = createEvent();
export const setHospitalsPagination = createEvent();
export const resetHospitals = createEvent();
export const resetHospitalsPagination = createEvent();
export const setIsElective = createEvent();
export const resetElective = createEvent();
export const fetchRestrictions = createEvent();
export const setConfigs = createEvent();
export const resetData = createEvent();
export const setReasons = createEvent();
export const fetchAppointment = createEvent();

const _fetchHospitals = createEffect();
const _fetchRestrictions = createEffect();
const _fetchLocations = createEffect();
//
const changeUsersSearchDebounce = createDebounce(changeUsersSearch, 500);
const changeStatusDebounce = createDebounce(changeStatus, 500);
const _fetchUsers = createEffect();
//
const changeSearchDebounce = createDebounce(changeSearch, 500);
const _fetchAppointment = createEffect();

$appointmentParent.on(setAppointmentParent, (_, params) => params).reset(resetAppointmentParent);

$appointmentData
  .on(setAppointment, (_, result) => result)
  .reset(setDefaultAppointment);
$isReasonModalVisible.on(
  toggleReasonModal,
  () => !$isReasonModalVisible.getState(),
);
$isSent.on(sentConfirmationEmail, () => true).on(setIsSentDefault, () => false);

$pagination
  .on(setPagination, (_, pagination) => pagination)
  .reset(changeSearch);

$hospitalsPagination
    .on(setHospitalsPagination, (_, pagination) => pagination)
    .reset(resetHospitalsPagination);

    //
    $usersPagination
  .on(changeUsersPagination, (_, params) => params)
  .on(
    [
      $userSearch,
       $status,
    ],
    (state) => ({
      ...state,
      current: 1,
    }),
  )
  .on(_fetchUsers.done, (state, { result }) => ({
    ...state,
    total: result.count,
  }))

  .on(changeStatus, (state) => ({
    ...state,
    current: 1,
  }))
  .on(changeUsersSearch, (state) => ({
    ...state,
    current: 1,
  }))
    //

$isElective
  .on(setIsElective, (_, params) => params)
  .reset(resetElective);

_fetchRestrictions.use(() => client
  .get('/api/v1/restrictions/', {
    params: {
      userId: $appointmentUser?.getState?.()?.id,
    },
  })
  .then((response) => get(response, 'data', [])));

_fetchHospitals.use(async ({isActive, isManagement, additionalParams, cancelToken, id}) => {
  const pagination = $hospitalsPagination.getState();
  const hospitals = $hospitals.getState();
  const options = {
    params: {
      id,
      ordering: 'title',
      offset: (pagination.current - 1) * pagination.pageSize,
      // limit: pagination.pageSize,
      management: isManagement,
      ...additionalParams,
      isActive,
    },
  };
  if (pagination?.count > pagination?.total || !pagination?.total) {
    const response = await client.get('/api/v1/hospitals/', {
      ...options,
      archived: false,
      cancelToken,
    })
      .catch((error) => {
        const message = get(error, 'message', '');
        notification.error({ message });
      });

    setHospitalsPagination({
      ...pagination,
      current: pagination.current + 1,
      total: pagination.total + get(response, 'data.results', []).length,
      count: get(response, 'data.count', null),
    })

    return pagination.current === 1 ? get(response, 'data.results', []) : [...hospitals, ...get(response, 'data.results', [])];
  }
});

_fetchLocations.use((onSearchChange) => {
  const pagination = $pagination.getState();
  const search = $search.getState();
  const locations = $locations.getState();
  const options = {
    params: {
      ordering: 'title',
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      search,
    },
  };

  return client
    .get('/api/v1/locations/', options)
    .then((response) => {
      const data = {
        locations: onSearchChange
          ? [...get(response, 'data.results', [])]
          : [...locations, ...get(response, 'data.results', [])],
        count: get(response, 'data.count', null),
        ordering: 'title',
      };

      setPagination({
        ...pagination,
        current: pagination.current + 1,
        total: pagination.total + get(response, 'data.results', []).length,
      });

      return data;
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    });
});

// console.log("$currentUser",$user);

let role;

_fetchAppointment.use(() => Promise.all([

  // role = $currentUser.getState().role.includes('super')||$currentUser.getState().role.includes('call_centre')||$currentUser.getState().role.includes('test_centre')?'Manager_Reasons':'User_Reasons',
  // console.log("role is",role),
  client.get('/api/v1/CancellationResonsList/'),
])
.then(async (responses) => {
  const [configsRes] = responses;
  const configs = get(configsRes, 'data', {});
  setConfigs(configs);
  const reasonsData = configs.results;
  console.log(JSON.stringify(reasonsData));
  // const orderedReasons = orderBy(reasonsData, [(reason) => reason.value.toLowerCase()], ['asc']);
  console.log("ordered Reasons =" + JSON.stringify(reasonsData));
  setReasons(reasonsData);

}));


_fetchUsers.use(() => {
  const search = $userSearch.getState();
  const status = $status.getState();
  const pagination = $usersPagination.getState();

  const options = {
    params: {

      management: true,
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      search,
      status:status,

    },
  };

  return client
    .get('/api/v1/users/', options)
    .then((response) => {
      console.log("response is",response);
      const data = {
        count: get(response, 'data.count', null),
        users: get(response, 'data.results', []).map((user) => ({
          ...user,
          role: user.role.map((r) => camelCase(r)),
        })),
      };
      console.log("data is",response);

      return data;
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    });
});

fetchLocations.watch(() => _fetchLocations());
//
fetchUsers.watch(()=>_fetchUsers());
//
fetchHospitals.watch((data) => _fetchHospitals(data));
changeSearchDebounce.watch(() => _fetchLocations(true));
//
changeUsersSearchDebounce.watch(() => _fetchUsers(true));
changeStatusDebounce.watch(() => _fetchUsers(true));
//
fetchAppointment.watch(() => _fetchAppointment());
$locations.on(_fetchLocations.done, (_, { result }) => result.locations);
$configs.on(setConfigs, (_, params) => params);
$reasons.on(setReasons, (_, params) => params);

$search.on(changeSearch, (_, params) => params).reset(clearSearch);
//
$userSearch.on(changeUsersSearch, (_, params) => params).reset(clearSearch);
$status.on(changeStatus, (_, params) => params).reset(clearSearch);

//


//
$users
  .on(_fetchUsers.done, (_, { result }) => result.users)
  .reset(resetUsers);

//
$procedureUid.on(setProcedureUid, (_, params) => params).reset(resetProcedureUid);
$loading.on(_fetchLocations, () => true).on(_fetchLocations.done, () => false);
$isLoading.on(_fetchAppointment, () => true)
        .on(_fetchAppointment.done, () => false)
        .on(_fetchAppointment.fail, () => false)
        //
        .on(_fetchUsers, () => true)
        .on(_fetchUsers.done, () => false)
        .on(_fetchUsers.fail, () => false);
        //
$hospitals
    .on(_fetchHospitals.done, ( _, { result }) => result)
    .reset(resetHospitals);
$hospitalLoading
    .on(_fetchHospitals, () => true)
    .on(_fetchHospitals.done, () => false);
