import React from 'react';
import './index.scss';
import classnames from 'classnames';

function Tip({
  text, style, className, secondText,
}) {
  return (
    <div style={style} className={classnames({ tipContainer: true, [className]: className !== undefined })}>
      Tip:
      {' '}
      {text}
      <br />
      {secondText}
    </div>
  );
}

export default Tip;
