import React from 'react';
import { Space } from 'antd';

import './RoleTags.scss'

const RoleTags = props => {
  const { record, rolesMap } = props;
  const { role } = record;

  return (
    <Space className="roleTags" size={8} wrap>
      {role.map(role => (
        <div key={role} className={`roleTag ${role}`}>
          {rolesMap[role]}
        </div>
      ))}
    </Space>
  );
};

export default RoleTags;
