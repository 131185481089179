import React from 'react';

import './index.scss';
import Tooltip from '../Tooltip';


function Labeled({
  children, className, title, errorText, isValid, tooltip = null,
}) {
  return (
    <div
      className={`Labeled-Container ${className} ${errorText ? 'Error' : ''}`}
    >
      {
        title && !tooltip && <span className="Labeled-title">{title}</span>
      }
      {
        title && tooltip && <Tooltip title={tooltip} className={className}><span className="Labeled-title">{title}</span></Tooltip>
      }
      {children}
      {!isValid && <span className="Labeled-error">{errorText}</span>}
    </div>
  );
}

export default Labeled;
