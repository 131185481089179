import decode from "jwt-decode";

export default function decodeToken(token) {
  let decodedToken = null;

  try {
    decodedToken = decode(token);
  } catch (error) {
    decodedToken = null;
  }

  if (!token) {
    decodedToken = null;
  }

  return decodedToken;
}
