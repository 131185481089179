import { client } from '../../helpers/http';

export const getUserProfileById = async (id, isManagement) =>
  client.get(`/api/v1/users/${id}/`, {
    params: {
      management: isManagement,
    },
  });

export const editUserProfileById = async (id, date) =>
  client.patch(`/api/v1/users/${id}/`, date);

export const sendSignature = (newFormData) =>
  client.post('/api/v1/consents/', newFormData);


export const createSendCommunication = (newFormData) =>
  client.post('/api/v1/SendCommunicationResponse/', newFormData);
// added send otp api
export const sendOtp = (newFormData) =>
  client.post('/api/v1/send_otp/', newFormData);

export const verifyOtp = (newFormData) =>
  client.post('/api/v1/verify_otp/', newFormData);

export const patchSignature = (consentsId, appointmentId) =>
  client.patch(`/api/v1/consents/${consentsId}/`, { appointment: appointmentId });

export const sendAntibodySignature = (newFormData) =>
  client.post('/api/v1/signature/', newFormData);

export const getAllOrganizations = () =>
  client.get('/api/v1/organizations/', {
    params: {
      ordering: 'title',
      limit: 100000,
    },
  });

export const getAllHospitals = (isManagement, additionalParams, cancelToken) =>
  client.get('/api/v1/hospitals/', {
    params: {
      limit: 200,
      archived: false,
      ordering: 'title',
      management: isManagement,
      ...additionalParams,
    },
    cancelToken,
  });

export const getOrganisationById = (id) =>
  client.get(`/api/v1/organizations/${id}/`, {
    params: {
      limit: 200,
    },
  });

export const getAllActiveHospitals = (
  isManagement,
  additinalParams,
  cancelToken,
) =>
  client.get('/api/v1/hospitals/', {
    params: {
      limit: 200,
      ordering: 'title',
      archived: false,
      management: isManagement,
      isActive: true,
      ...additinalParams,
    },
    cancelToken,
  });

export const getAllDepratments = async () =>
  client.get('/api/v1/departments/?limit=100');

export const getLocations = () =>
  client.get('/api/v1/locations/', {
    params: {
      limit: 2000,
      ordering: 'title',
    },
  });

export const getAllPositions = async () =>
  client.get('/api/v1/positions/?ordering=title&limit=100');

export const postNewPosition = (data) => client.post('/api/v1/positions/', data);
