import React from 'react';
import { createComponent } from 'effector-react';

import { $currentUser } from '../../../../models';
import {
  updateUser,
  handleUserModalOpen,
  setSelectedUser,
} from '../../model';

import ActionCell from './ActionCell';

const ActionCellContainer = createComponent(
  {
    updateUser,
    setSelectedUser,
    handleUserModalOpen,
    $currentUser,
  },
  (props, state) => {
    if (!state?.$currentUser?.role?.includes?.('super') || !props?.record?.isStaff) {
      return null;
    }

    return (
      <ActionCell
        {...props}
        handleUserModalOpen={state.handleUserModalOpen}
        updateUser={state.updateUser}
        setSelectedUser={state.setSelectedUser}
      />
    );
  },
);

export default ActionCellContainer;
