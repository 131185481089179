import React from 'react';
import { Space } from 'antd';

import './StatusCell.scss'

const StatusCell = props => {
  const { record } = props;
  const { isActive } = record;
  const { verification} = record;
  const {isCcb} = record;
  const status = isActive ? "emailVerified" : "emailNotVerified";
  const statusText = isActive ? "EMAIL VERIFIED" : "EMAIL NOT VERIFIED";
  const profileNotFilled = verification === 'not_verified';
  return (
    <div>
      <Space className="status" size={8} wrap>
      {!isCcb && 
      
        <div key={status} className={`statusTags ${status}`}>
         {statusText}
        </div>}
         {isCcb && 
         <div key={status} className="statusTags ccb">
          CCB
         </div>
        } 
         {profileNotFilled && 
      <div key={status} className="statusTags profileNotFilled">
       PROFILE NOT FILLED
      </div>
   }
    </Space>
   
    </div>
    
  );
};

export default StatusCell;
