import { createStore, createEvent } from 'effector';

export const possibleStatuses = {
    filled: 'filled',
    inProgress: 'inProgress'
}

export const possibleSteps = {
    subjectForProcedure: 'Subject for procedure',
    procedureType: 'Procedure type',
    searchProfiles: 'Search User Profiles',
    recipientForm:'Recipient form',
    symptoms: 'Symptoms',
    selectLocation: 'Select location',
    schedule: 'Schedule',
    checklist: 'Checklist',
    electiveInfo: 'Elective Patient Info',
}

export const $steps = createStore([]);
export const $currentStep = createStore(null);

export const setCurrentStep = createEvent();
export const revertTillStep = createEvent();

export const setNextStep = createEvent();
export const goNextStep = createEvent();
export const goPrevStep = createEvent();

export const resetStepper = createEvent();

$steps
    .on(setNextStep, (steps, result) =>{
        const newSteps = steps;
        if(newSteps.length > 0){
            newSteps[newSteps.length -1].status = possibleStatuses.filled
        }
        newSteps.push({title:result, status: possibleStatuses.inProgress})
        return newSteps;
    })
    .on(revertTillStep, (steps, result) => {
        const newSteps = steps;
        const currentStep = $currentStep.getState();
        const stepIndex = newSteps.findIndex((step)=> step.title === result)
        if(stepIndex > -1){
            newSteps.splice(stepIndex+1, newSteps.length - stepIndex)
            newSteps[newSteps.length-1].status = possibleStatuses?.inProgress
            return newSteps
        }
            const currentStepIndex = newSteps.findIndex((step)=> step.title === currentStep)
            newSteps.splice(currentStepIndex+1, newSteps.length - currentStepIndex)
            setNextStep(result)

    })
    .on(resetStepper, ()=>[])

$currentStep
    .on(setCurrentStep, (_, result) => result)
    .on(revertTillStep, (_, result) => result)
    .on(setNextStep, (_, result) => result)
    .on(goNextStep, (currentStep) => {
        const steps = $steps.getState();
        const currentIndex = steps.findIndex((step)=> step.title === currentStep)
        if(currentIndex + 1 <= steps.length){
            return steps[currentIndex+1].title
        }
    })
    .on(goPrevStep, (currentStep) => {
        const steps = $steps.getState();
        const currentIndex = steps.findIndex((step)=> step.title === currentStep)
        if(currentIndex - 1 > -1){
            return steps[currentIndex-1].title
        }
    })
    .on(resetStepper, ()=>null)
