import React, { useState } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';

import { User } from '../../assets';

import './index.scss';

const AvatarContainer = (props) => {
  const {
    firstName,
    lastName,
    signOut,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="Avatar">
      <Dropdown
        overlay={(
          <Menu mode="inline">
            <Menu.Item>
              <Link to="/profile">Profile Settings</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/change-password">Change password</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={signOut}>Log out</Menu.Item>
          </Menu>
        )}
        onVisibleChange={setIsVisible}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <div className="Avatar-Container">
          <Avatar size={35} icon={<User />} />
          <span className="Avatar-FullName">
            <span style={{ marginRight: '5px' }}>
              {firstName}
              {' '}
              {lastName}
            </span>

          </span>
          {isVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
        </div>
      </Dropdown>
    </div>
  );
};

export default AvatarContainer;
