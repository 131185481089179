/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'formdata-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';
// import { unregister as unregisterServiceWorker } from './registerServiceWorker'

let configUrl=window.location.href;
console.log("configUrl",configUrl);
let UrlUID="";
if(configUrl.includes("eput")){
  UrlUID='/eput';
}
else if(configUrl.includes("mse")){
  UrlUID='/mse';
}
else{
  UrlUID='/mse';
}
console.log("UrlUID is",UrlUID);
ReactDOM.render(
  <BrowserRouter basename={UrlUID}>
    <AppContainer />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
