/* eslint-disable react-hooks/exhaustive-deps */
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import { accountTypes } from "./constants";


const useMenuItems = (profile) => {
  const location = useLocation();
  const appointmentsMatch = useRouteMatch('/appointments') && !location?.state?.fromUsers;
  const managersMatch = useRouteMatch('/manager');
  const changePasswordMatch = useRouteMatch('/change-password');
  const isUsersPage = useRouteMatch('/users') || location?.state?.fromUsers;
  const isCallCentreBooking = useRouteMatch('/book-appointment');
  const isSchedule = useRouteMatch('/schedule');
  const isOtherSettings = useRouteMatch('/other-settings');
  const isAnalytics = useRouteMatch('/analytics');
  const isReports = useRouteMatch('/reports');
  const isSendCommunication = useRouteMatch('/SendCommunication');
  const isUserSegment = useRouteMatch('/user-segment');
  const isCreateUserProfile = useRouteMatch('/create-user-profile');

  const isStaff = profile?.isStaff;
  const isStage = window.location.href.includes('covid-stage');
  const isSuperUser = profile?.role?.includes?.('super');
  const isUrgentManager = profile?.role?.includes?.('call_centre');
  const isTestCenterManager = profile?.role?.includes?.('test_centre');
  const isProdENV = process.env.REACT_APP_ENV === 'master';
  const isMSE = profile?.account?.uid === accountTypes.midessex;
  const isCommisceo = profile?.account?.uid === accountTypes.commisceo;
  const isWessex = profile?.account?.uid === accountTypes.wessex;
  const isEPUT = profile?.account?.uid === accountTypes.EPUT;
  const isCheck = profile?.account?.uid;

  return useMemo(
    () =>
      [
        !isUrgentManager && {
          title: 'My Tests',
          to: '/appointments',
          active: appointmentsMatch,
        },
          isSuperUser && (isMSE||isEPUT) && isStage && {
            title: 'Create User Profile',
            to: '/create-user-profile',
            active: isCreateUserProfile,
          },
        isStaff &&
          !isUrgentManager && {
            title: 'View Cases',
            to: '/manager/appointments',
            active: managersMatch,
          },
        (isStaff || isSuperUser) &&
          !isUrgentManager && {
            title: 'Users',
            to: '/users',
            active: isUsersPage,
          },
        ((isCheck) ?(isUrgentManager || isTestCenterManager || isSuperUser) : isUrgentManager) && {
          title: 'Call Centre Bookings',
          to: '/book-appointment',
          active: isCallCentreBooking,
        },
        isSuperUser &&
          !isProdENV &&
          !isUrgentManager && {
            title: 'Analytics',
            to: '/analytics',
            active: isAnalytics,
          },

          isSuperUser &&
          !isProdENV &&
          !isUrgentManager && {
            title: 'Reports',
            to: '/reports',
            active: isReports,
          },


          // {
          //   title: 'Send Communication',
          //   to: '/SendCommunication',
          //   active: isSendCommunication,

          // },


        {
          title: 'Change password',
          to: '/change-password',
          active: changePasswordMatch,
          isMobileOnly: true,
        },
        isStaff &&
          isSuperUser &&
          !isUrgentManager && {
            title: 'Schedule',
            to: '/schedule',
            active: isSchedule,
          },

          isSuperUser &&
          !isProdENV &&
          !isUrgentManager && {
            title: 'User Segmentation',
            to: '/user-segment',
            active: isUserSegment,
          },

        isSuperUser &&
          !isUrgentManager && {
            title: 'Other Settings',
            to: '/other-settings',
            active: isOtherSettings,
          },
      ].filter(Boolean),
    [
      appointmentsMatch,
      profile,
      managersMatch,
      isUsersPage,
      changePasswordMatch,
      isSchedule,
      isOtherSettings,
      isAnalytics,
      isReports,
      isSendCommunication,
    ],
  );
};

export default useMenuItems;
