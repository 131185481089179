import React from 'react';
import { createComponent } from 'effector-react';

import {
  activateUser,
  deleteUser,
  setModalVisible,
  setSelectedUser,
  setSelectedAction,
} from '../../model';

import BlockedActionsCell from './BlockedActionsCell';

const BlockedActionsCellContainer = createComponent(
  {
    activateUser,
    deleteUser,
    setModalVisible,
    setSelectedUser,
    setSelectedAction,
  },
  (props, state) => (
      <BlockedActionsCell
        {...props}
        activateUser={state.activateUser}
        deleteUser={state.deleteUser}
        setModalVisible={setModalVisible}
        setSelectedUser={state.setSelectedUser}
        setSelectedAction={state.setSelectedAction}
      />
    ),
);

export default BlockedActionsCellContainer;
