import React, { useMemo, useCallback } from 'react';
import { Table, Button } from 'antd';

import RoleTags from '../components/RoleTags';
import ActionCell from '../components/ActionCell';
import UserNameCell from '../components/UserNameCell';
import BlockedActionsCell from "../components/BlockedActionsCell";

import './UsersTable.scss';
import {usersTableVisibleParams} from "../../../helpers/constants";

import moment from "moment";

import ActivateModal from "../components/ActivateModal";
import StatusCell from '../components/StatusCell/StatusCell';

const UsersTable = (props) => {
  const {
    isLoading,
    users,
    pagination,
    changePagination,
    rolesMap,
    history,
    selectedRow,
    changeSelectedRow,
    isStaffFilter,
    activeTab,
    isModalVisible,
    setModalVisible,
    selectedUser,
    activateUser,
    selectedAction,
    deleteUser,
    resendInvite,
  } = props;

console.log("users are",selectedRow);
  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleResendInvite = useCallback((event) => {
    event.stopPropagation();
    //console.log("Resend Invite ", JSON.stringify(event));
  })

  const resendAction = (record) => {
    const handleResendInvite = useCallback((event) => {
      event.stopPropagation();
      console.log("Resend Invite ", JSON.stringify(record));
      resendInvite(record.email);
    })
    return (
      !record.isActive && <Button onClick={handleResendInvite} > Resend </Button>
    );
  }

  const handleOk = useCallback(() => {
    if (selectedAction === 'activate') {
      activateUser({userId: selectedUser.id})
    } else {
      deleteUser({userId: selectedUser.id})
    }
    setModalVisible(false);
  }, [selectedUser, selectedAction]);

  const rowSelection = {
    fixed: 'left',
    hideDefaultSelections: true,
    selectedRowKeys: selectedRow,
    onChange: changeSelectedRow,
  };

  const columns = useMemo(() => [
    {
      key: 'fullName',
      title: 'Users',
      width: 270,
      ellipsis: true,
      className: 'usersHeadline',
      visible: usersTableVisibleParams.all,
      render: (_, record) => <UserNameCell record={record} />,
    },
    {
      key: 'testCentre',
      title: 'Test Centre',
      width: 250,
      ellipsis: true,
      visible: usersTableVisibleParams.staff,
      render: (_, record) => record.hospitals.map((hospital) => hospital.title).join(', '),
    },
    {
      key: 'managerRoles',
      title: 'Manager Roles',
      ellipsis: true,
      width: 140,
      visible: usersTableVisibleParams.staff,
      render: (_, record) => <RoleTags record={record} rolesMap={rolesMap} />,
    },
    {
      key: 'status',
      title: 'Status',
      ellipsis: true,
      width: 140,
      visible: usersTableVisibleParams.all,
      render: (_, record) => <StatusCell record={record} />,
    },
    {
      key: 'dob',
      title: 'Date Of Birth',
      ellipsis: true,
      width: 200,
      visible: usersTableVisibleParams.patients,
      render: (_, record) => record?.dateOfBirth && moment(record?.dateOfBirth).format('DD/MM/YYYY'),
    },
    {
      key: 'lastLogin',
      title: 'Last Login',
      ellipsis: true,
      width: 140,
      visible: usersTableVisibleParams.all,
      render: (_, record) => record?.lastLogin && moment(record?.lastLogin).format('DD/MM/YYYY HH:mm'),
    },
    {
      key: 'organization',
      title: 'Organization',
      ellipsis: true,
      visible: usersTableVisibleParams.patients,
      render: (_, record) => record?.organization?.title,
    },
    {
      key: 'actions',
      title: '',
      width: 30,
      blockedTab: false,
      visible: usersTableVisibleParams.staff,
      render: (_, record) => <ActionCell record={record} />,
    },
    {
      key: 'resend',
      title: '',
      width: 100,
      blockedTab: false,
      visible: usersTableVisibleParams.staff,
      render: (_, record) =>  resendAction(record),
    },
    {
      key: 'actions',
      title: '',
      width: 100,
      blockedTab: true,
      visible: usersTableVisibleParams.all,
      render: (_, record) => (
        <BlockedActionsCell
          record={record}
        />
      ),
    }
  ].filter(({visible, blockedTab}) => {
    if (activeTab === 'duplicated') {
       if (blockedTab === false) {
         return null;
       }
       return (visible === usersTableVisibleParams.patients && !isStaffFilter)
      || (visible === usersTableVisibleParams.staff && isStaffFilter)
      || visible === usersTableVisibleParams.all
    }
      return ((visible === usersTableVisibleParams.patients && !isStaffFilter)
        || (visible === usersTableVisibleParams.staff && isStaffFilter)
        || visible === usersTableVisibleParams.all) && !blockedTab
    }
  ), [rolesMap, isStaffFilter, activeTab]);


  const onRow = useCallback(
    (record) => ({
      onClick: () => history.push(`/users/user/${record.id}`),
    }),
    [history],
  );

  return (
    <div className="usersTable">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={users}
        pagination={pagination}
        onChange={changePagination}
        loading={isLoading}
        onRow={onRow}
        // rowSelection={activeTab === 'singular' ? rowSelection : null}   //change this line to below line to allow select option in diplicate tab
        rowSelection={activeTab === 'singular' ? rowSelection : rowSelection}
        scroll={{ x: 1200 }}
      />
      <ActivateModal props={{
        isModalVisible,
        handleCancel,
        handleOk,
        selectedAction,
        selectedUser,
      }} />
    </div>
  );
};

export default UsersTable;
