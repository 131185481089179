/* eslint-disable react-hooks/exhaustive-deps */
import React , {useMemo} from 'react';
import { createComponent } from 'effector-react';
import './index.scss';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { Close } from '../../assets';
import { $currentUser, $showResendEmailNotif, setShowResendEmailNotif } from '../../models'

const ResendNotification = ({currentUser, showResendEmailNotif, setShowResendEmailNotif}) => {
  const emailNotVerified = localStorage.getItem('emailNotVerified')
  const inactivAuthFailed = localStorage.getItem('inactivAuthFailed')

  useMemo(()=>{
    setShowResendEmailNotif((emailNotVerified === 'true' || inactivAuthFailed === 'true') && isEmpty(currentUser))
  },[emailNotVerified, currentUser, inactivAuthFailed])

  if(!showResendEmailNotif){
      return null
  }
  return (
    <div className="notificationWrapperResendEmail">
      <div className="notificationContent">
        <p>
        Your email is not verified yet. Please find Email 
        Confirmation letter in your email box and follow the 
        link in it to verify email and activate your account. 
        If you did not receive the email, please check your JUNK/SPAM folders in your email. 
        If you have lost the email, please click <Link to='/resend-email'>Resend email</Link> to get another one. 
        </p>
        <div className="notificationCloseWrapper" onClick={()=>{setShowResendEmailNotif(false)}}>
          <Close />
        </div>
      </div>
    </div>
  );
};

const ResendNotificationContainer = createComponent(
  {
    $currentUser, 
    $showResendEmailNotif, 
    setShowResendEmailNotif 
  },
  (props, state) => {

    return (
        <ResendNotification
          {...props}
          currentUser={state.$currentUser}
          showResendEmailNotif={state.$showResendEmailNotif}
          setShowResendEmailNotif={state.setShowResendEmailNotif}
        />
    );
  },
);

export default ResendNotificationContainer;
