import React, { useState } from "react";
import "./index.scss";
import { Eye, EyeActive } from "../../assets";

import { Input } from "antd";

function PasswordInput({ ...rest }) {
  const [passwordIsShown, setPasswordIsShown] = useState(false);

  return (
    <div className="PasswordInput-Container">
      <Input
        autoComplete="off"
        type={passwordIsShown ? "text" : "password"}
        {...rest}
      />
      <div
        className="PasswordInput_Eye-Container"
        onClick={() => setPasswordIsShown(!passwordIsShown)}
      >
        {passwordIsShown ? <EyeActive /> : <Eye />}
      </div>
    </div>
  );
}

export default PasswordInput;
