/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const InfoCard = (props) => {
  const {
    title, message, backButton, reloadButton,
  } = props;
  const handleRefreshPage = useCallback(() => {
    window.location.reload();
  });

  return (
    <div className="InfoCard">
      <h2>{title}</h2>
      <span>
        {message}
      </span>
      {(backButton || reloadButton) && (
        <div className="buttonsRow">
          {backButton && (
            <Link to="/">
              <Button type="default">Back</Button>
            </Link>
          )}
          {reloadButton && (
            <Button type="primary" className="reloadButton" onClick={handleRefreshPage}>Reload page</Button>
          )}
        </div>
      )}
    </div>
  );
};

export default InfoCard;
