/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from 'react';
import { Button, Checkbox, Radio, Steps } from 'antd';
import purify from "dompurify";
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import './index.scss';

const { Step } = Steps;

const ConsentContentCheckbox = memo(
  ({
    answersScheme,
    setAnswers,
    setAnswersValidated,
    setDeclined,
    declined,
    setCanceledValidated,
    canceledValidated,
    setCancelStatements,
    setDeclinedStatements,
    setCancelled,
    setSelectedType
  }) => {
    const stepsState = -1

    const [checkboxes, setCheckboxes] = useState({});
    const [radiobuttons, setRadiobuttons] = useState({});
    const [radiobuttonsValue, setRadiobuttonsValue] = useState({});
    const [acceptations, setAcceptations] = useState({});
    const [radioChoosed, setRadionChoosed] = useState(false);

    const formatCheckboxAnswers = (scheme) => {
      const answers = {};
      scheme.forEach(([question, value]) => {
        answers[question] = value.value;
      });
      return answers;
    };

    const formatRadioAnswers = (scheme) => {
      const answers = {};
      scheme.forEach(([_, value]) => {
        answers[value] = true;
      });
      return answers;
    };

    useEffect(() => {
      if (declined) {
        const cancelCheckBoxesArray = Object.entries(checkboxes).filter(
          ([, value]) => value.cancel,
        );

        const unckedAllCancel = {};

        cancelCheckBoxesArray.forEach(([key, value]) => {
          unckedAllCancel[key] = { ...value, value: false };
        });

        setCheckboxes({ ...checkboxes, ...unckedAllCancel });
      }
    }, [declined]);

    useEffect(() => {
      const filteredRequiredCheckboxes = Object.entries(checkboxes).filter(
        ([key, value]) => value.required,
      );
      const filteredCheckedCheckboxes = Object.entries(checkboxes).filter(
        ([key, value]) => value.value,
      );
      const cancelCheckBoxesArray = Object.entries(checkboxes).filter(
        ([key, value]) => value.cancel,
      );

      const validatedCheckboxes = Boolean(
        filteredRequiredCheckboxes.every(([key, value]) => {
          let newValue = value;
          const acceptation = Object.entries(acceptations).every(
            ([key, value]) => value === radiobuttonsValue[key],
          );
          if (!acceptation && newValue.acceptation) {
            newValue = { ...newValue, required: false };
          }
          if (newValue.required && !newValue.value) {
            return false;
          }
          return true;
        }),
      );

      const radioButtonsArray = Object.entries(radiobuttons);
      const radioButtonsValueArray = Object.entries(radiobuttonsValue);

      let canceledChecked = false;

      const acceptaion = isEmpty(acceptations) ? true : radioButtonsValueArray.every(
        ([key, value]) => acceptations[key] === value,
      );

      setDeclined(!acceptaion);

      const notCheckedCheckboxes = [];

      if (cancelCheckBoxesArray?.length > 0) {
        canceledChecked = !acceptaion
          ? false
          : !cancelCheckBoxesArray.every(([key, value]) => value?.value);
        cancelCheckBoxesArray.forEach(([key, value]) => {
          if (!value?.value) {
            notCheckedCheckboxes.push(value?.text);
          }
        });

        if (!canceledValidated) {
          const someChecked = cancelCheckBoxesArray.some(([key, value]) => value?.value);

          if (someChecked) {
            setCanceledValidated(true);
          }
        }
      }
      const declineReasons = Object.values(radiobuttonsValue)

      setCancelled(canceledChecked);
      setCancelStatements(notCheckedCheckboxes);
      if(setDeclinedStatements){
        setDeclinedStatements(declineReasons);
      }

      const validatedRadio = Boolean(
        radioButtonsArray.every(([key, value]) => value),
      );

      setAnswersValidated(validatedCheckboxes && validatedRadio);
      setAnswers({
        ...formatCheckboxAnswers(filteredCheckedCheckboxes),
        ...formatRadioAnswers(radioButtonsValueArray),
      });
    }, [checkboxes, radiobuttonsValue, radiobuttons]);

    const setRadio = (step) => (EO) => {
      setRadiobuttonsValue({ ...radiobuttonsValue, [step]: EO.target.value });
      setRadionChoosed(true);
      if (radiobuttons?.[step] === false) {
        setRadiobuttons({ ...radiobuttons, [step]: true });
      }
    };

    const checkboxToggle = (key) => {
      setCheckboxes({
        ...checkboxes,
        [key]: { ...checkboxes[key], value: !checkboxes[key].value },
      });
    };

    const adaptScheme = (scheme) => {
      let checkboxState = {};
      let radiobuttonsState = {};
      const acceptations = {};

      const checklist = scheme?.checklist;

      checklist.forEach((question) => {
        const answers = question?.answers;
        const acceptation = question?.acceptation;
        const cancel = question?.cancel;
        if (typeof acceptation === 'string') {
          acceptations[question.step] = acceptation;
        }

        if (question?.type === 'checkbox') {
          answers.forEach((answer) => {
            checkboxState = {
              ...checkboxState,
              [answer?.text]: { ...answer, acceptation, cancel },
            };
          });
        }
        if (question?.type === 'radiobutton') {
          radiobuttonsState = {
            ...radiobuttonsState,
            [question.step]: false,
            acceptation,
          };
        }
      });

      setCheckboxes(checkboxState);
      setRadiobuttons(radiobuttonsState);
      setAcceptations(acceptations);
    };

    useEffect(() => {
      if (answersScheme) {
        adaptScheme(answersScheme);
      }
    }, [answersScheme]);

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    const buildQuestions = (answersScheme) => {
      const checklist = answersScheme?.checklist;
      if (checklist) {
        // hmmm, a function with properties passed to it which returns an element tree
        // wonder if it looks familiar...
        const buildQuestion = ({ question, renderAnswer, }) => {
          const grouped = groupBy(
            _.map(question?.answers, (a) => ({ ...a, group: a.group || '' })),
            'group',
          );
          return (
            <>
              {question?.question && (
                <h4 className="checklistVaccinating_subtitle">
                  {question?.question}
                </h4>
              )}
              {Object.entries(grouped)?.map(([key, answers]) => (
                <>
                  {key && key !== 'null' && (
                    <h4 className="checklistVaccinating_subtitle">{key}</h4>
                  )}
                  <div className="checklistVaccinating_checkboxWrapper">
                    {answers.map((answer) =>
                      // checkboxState = { ...checkboxState, [answer?.text]: answer };
                       (
                        <>
                          {answer?.tip && (
                            <div
                              style={{ marginBottom: '12px' }}
                              className="footnote"
                              dangerouslySetInnerHTML={{
                                __html: purify.sanitize(answer?.tip, { ADD_ATTR: ['target'] }),
                              }}
                            />
                          )}
                          {renderAnswer(answer)}

                        </>
                      )
                    )}
                  </div>
                </>
              ))}
            </>
          );
        };
        const buildCheckboxes = ({ question, disabled }) =>
          buildQuestion({
            question,
            disabled,
            renderAnswer: (answer) => (
              <Checkbox
                disabled={disabled}
                value={checkboxes?.answer?.value} // what is that?
                className="checklistVaccinating_consentCheckbox"
                checked={checkboxes?.[answer?.text]?.value}
                onClick={() => checkboxToggle(answer?.text)}
              >
                {answer?.text}
              </Checkbox>
            ),
          });

        const buildRadio = ({ question }) => {
          const step = question?.step;
          return (
            <Radio.Group
              onChange={setRadio(step)}
              value={radiobuttonsValue?.[step] || null}
              style={{ display: 'block' }}
            >
              {buildQuestion({
                question,
                renderAnswer: (answer) => (
                  <Radio
                    style={radioStyle}
                    value={answer?.text}
                    className="checklistVaccinating_consentRadio"
                    onClick={()=> setSelectedType ? setSelectedType(answer?.subtype) : null}
                  >
                    {answer?.text}
                  </Radio>
                ),
              })}
            </Radio.Group>
          );
        };

        const resultComp = (
          <Steps direction="vertical" current={stepsState}>
            {checklist?.map((question, index) => {
              let result = null;
              const status =
                (radioChoosed ? radioChoosed && declined : true) &&
                question?.cancel
                  ? 'wait'
                  : 'process';

              if (question?.type === 'checkbox') {
                const disabledCheckbox = status === 'wait';
                result = buildCheckboxes({
                  question,
                  disabled: disabledCheckbox,
                });
              }
              if (question?.type === 'radiobutton') {
                result = buildRadio({ question });
              }

              return (
                <Step
                  status={status}
                  title="Step"
                  description={
                    <div>
                      <div
                        className="checklistVaccinating_questionTip"
                        dangerouslySetInnerHTML={{ __html: purify.sanitize(question?.tip, { ADD_ATTR: ['target'] }) }}
                      />
                      {result}
                    </div>
                  }
                />
              );
            })}
          </Steps>
        );

        return resultComp;
      }
    };

    return (
      <div className="concentAntibody">{buildQuestions(answersScheme)}

      </div>
    );
  },
);

export default ConsentContentCheckbox;
