import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row } from 'antd';

import { Logo } from '../../components';
import { MultiTenancyHoc } from '../../helpers';
import { accountTypes } from '../../helpers/constants';

import './index.scss';

const { Footer } = Layout;

function FooterComponent() {
  return (
    <Footer className="Footer">
      <Row justify="space-between" className="Footer__InfoRow">
        <div className="Footer_Logo-Container">
          <Logo />
        </div>
        <div className="Contacts-Container">
          <ul className="Footer_Contacts-List-Numbers">
            <h4>CONTACTS</h4>
            <MultiTenancyHoc allowedRegions={[accountTypes.commisceo]}>
              <li>
                <p>
                Support telephone number 
                  &nbsp;
                  <span>0333 772 6144</span>
                </p>
              </li>
              <li>
                <p>
                  Lines are open
                  &nbsp;
                  <span>08:00 - 20:00 - 7 days per week</span>
                </p>
              </li>
              <li>
                <p>
                  Email 
                  &nbsp;
                  <span><a href="mailto:booking.enquires@nhs.net">booking.enquires@nhs.net</a></span>
                </p>
              </li>
            </MultiTenancyHoc>
            {/* added Contact for Eput Account */}
            <MultiTenancyHoc allowedRegions={[accountTypes.EPUT]}>
              <div className="Footer_Contacts-List_wrapper">
                <div>
                <li>
                  <p>
                    EPUT support number
                    &nbsp;
                    <span>0344 2573961</span>
                  </p>
                </li>
                <li>
                  <p>
                    (Mon-Fri 9am-5pm)
                    (Saturday, Sunday and Bank Holidays 10am – 4pm)
                  </p>
                </li>

                </div>
                <div>
                  <li>
                    <p>
                      Vaccination Support Line
                      &nbsp;
                      <span>0344 2573961 </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      (Mon-Fri 9am-5pm) (Saturday, Sunday and Bank Holidays 10am – 4pm)
                    </p>
                  </li>
                  <li>
                    <p>
                      Vaccination Enquiry Email
                    </p>
                    <span>Epunft.cv19.staffvaccinationinfo@nhs.net</span>
                  </li>
                </div>
              </div>
              {/* <li> */}
              {/*  <p> */}
              {/*    Vaccination Support Line */}
              {/*    &nbsp; */}
              {/*    <span>01245 515919</span> */}
              {/*  </p> */}
              {/* </li> */}
            </MultiTenancyHoc>
            {/* end Eput */}
            <MultiTenancyHoc allowedRegions={[accountTypes.midessex, accountTypes.norfolk]}>
              <div className="Footer_Contacts-List_wrapper">
                <div>
                <li>
                  <p>
                    MSE support number
                    &nbsp;
                    <span>01245 515919</span>
                  </p>
                </li>
                <li>
                  <p>
                    (Mon-Fri 9am-4pm)
                  </p>
                </li>
                <li>
                  <p>
                    Swabbing Enquiry Email <span>mse.stafftesting@nhs.net</span>
                  </p>
                </li>
                </div>
                <div>
                  <li>
                    <p>
                      Vaccination Support Line
                      &nbsp;
                      <span>01245 515919</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      (Mon-Fri 9am-4pm)
                    </p>
                  </li>
                  <li>
                    <p>
                      Vaccination Enquiry Email
                    </p>
                    <span>mse.mseft.covid-vaccination-queries@nhs.net</span>
                  </li>
                </div>
              </div>
              {/* <li> */}
              {/*  <p> */}
              {/*    Vaccination Support Line */}
              {/*    &nbsp; */}
              {/*    <span>01245 515919</span> */}
              {/*  </p> */}
              {/* </li> */}
            </MultiTenancyHoc>
            <MultiTenancyHoc allowedRegions={[accountTypes.wessex]}>
              <li>
                <p>
                  <span>
                    <a href="mailto:Westessexantibody.support@nhs.net">Westessexantibody.support@nhs.net</a>
                  </span>
                </p>
              </li>
            </MultiTenancyHoc>
          </ul>
          <ul className="Footer_Contacts-List-Legal">
            <h4>LEGAL</h4>
            <li>
              <Link to="/terms">Terms & Conditions</Link>
            </li>

            <MultiTenancyHoc allowedRegions={[accountTypes.midessex, accountTypes.norfolk, accountTypes.commisceo,accountTypes.EPUT]}>
              <li>
                <Link to="/privacy"> Privacy Policy</Link>
              </li>
            </MultiTenancyHoc>

          </ul>
        </div>
      </Row>
    </Footer>
  );
}

export default FooterComponent;
