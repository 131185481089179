import { createStore, createEffect, createEvent } from 'effector';

import { decodeToken } from '../helpers';
import {
  resetTabs,
} from "../pages/Users/model";

import { resetCurrentUser, setCurrentUser } from ".";


const initilizeUser = () => {
  if (decodeToken(localStorage.getItem('access-token-v1')) === null) {
    localStorage.removeItem('access-token-v1');
  }
  if (decodeToken(localStorage.getItem('refresh-token-v1')) === null) {
    localStorage.removeItem('refresh-token-v1');
  }
  return decodeToken(localStorage.getItem('access-token-v1'));
};

export const $decodedToken = createStore(initilizeUser());
export const $refreshExp = createStore(
  decodeToken(localStorage.getItem('refresh-token-v1'))?.exp * 1000,
);
export const $accessExp = createStore(
  decodeToken(localStorage.getItem('access-token-v1'))?.exp * 1000,
);
export const $accessGained = createStore(
  decodeToken(localStorage.getItem('access-token-v1'))?.ctime * 1000,
);
export const $refreshToken = createStore(null);
export const $lastActionDate = createStore(+new Date());
export const $account = createStore(null);
export const $token = createStore(null);

export const setDecodedToken = createEvent();
export const setRefreshExp = createEvent();
export const setAccessExp = createEvent();
export const setAccessGained = createEvent();
export const setRefreshToken = createEvent();
export const setLastActionDate = createEvent();
export const setToken = createEvent();

export const setInitialValues = createEvent();
export const signInSuccess = createEvent();
export const signOut = createEvent();
export const actionDateRefresh = createEvent();
export const sessionRefresh = createEvent();
export const setAccount = createEvent();

const _setInitialValues = createEffect();
const _signInSuccess = createEffect();
const _signOut = createEffect();
const _actionDateRefresh = createEffect();
const _sessionRefresh = createEffect();

$decodedToken.on(setDecodedToken, (_, params) => params);
$account.on(setAccount, (_, params) => params);
$refreshExp.on(setRefreshExp, (_, params) => params);
$accessExp.on(setAccessExp, (_, params) => params);
$accessGained.on(setAccessGained, (_, params) => params);
$refreshToken.on(setRefreshToken, (_, params) => params);
$lastActionDate.on(setLastActionDate, (_, params) => params);
$token.on(setToken, (_, params) => params);

_signInSuccess.use(({ data }) => {
  const { access, refresh } = data;
  resetTabs();
  setToken(access);
  localStorage.setItem('access-token-v1', access);
  localStorage.setItem('refresh-token-v1', refresh);
  localStorage.setItem(
    'lastActionDate',
    decodeToken(localStorage.getItem('access-token-v1'))?.ctime * 1000,
  );

  setDecodedToken({
    ...decodeToken(access),
    token: access,
  });
  setAccessExp(
    // make shorter session for tests
    // decodeToken(localStorage.getItem('access-token-v1'))?.exp * 1000 - 1177739
    decodeToken(localStorage.getItem('access-token-v1'))?.exp * 1000
  );
  // console.log('initial accec', decodeToken(localStorage.getItem('access-token-v1'))?.exp * 1000);
  setAccessGained(
    decodeToken(localStorage.getItem('access-token-v1'))?.ctime * 1000,
  );
  setRefreshExp(
    // make shorter session for tests
    // decodeToken(localStorage.getItem('refresh-token-v1'))?.exp * 1000
    decodeToken(localStorage.getItem('refresh-token-v1'))?.exp * 1000
  );
  setRefreshToken({
    ...decodeToken(refresh),
  });
  setLastActionDate(
    decodeToken(localStorage.getItem('access-token-v1'))?.ctime * 1000,
  );
});

_signOut.use(() => {
  localStorage.removeItem('access-token-v1');
  localStorage.removeItem('refresh-token-v1');
  localStorage.removeItem('api-token');
  setToken(null);
  setCurrentUser(null);
  setAccessExp(null);
  setAccessGained(null);
  setRefreshExp(null);
  setRefreshToken(null);
  setLastActionDate(null);
  setDecodedToken(null);
  setRefreshExp(0);
  resetCurrentUser();
});

_actionDateRefresh.use(() => {
  localStorage.setItem('lastActionDate', +new Date());
  setLastActionDate(+new Date());
});

_sessionRefresh.use(({ data }) => {
  const pogreshnost = 1000 * 2;
  const accessGained = $accessGained.getState();
  const accessExp = $accessExp.getState();
  setToken(data.access);
  localStorage.setItem('access-token-v1', data.access);
  const lastActionDate = localStorage.getItem('lastActionDate');

  const newDate = +new Date() + (lastActionDate - accessGained);

  const newAccessExp =
    lastActionDate - pogreshnost > accessGained ? newDate : accessExp;

  setRefreshExp(decodeToken(localStorage.getItem('refresh-token-v1'))?.exp * 1000);
  setAccessExp(newAccessExp);
  setAccessGained(decodeToken(data.access)?.ctime * 1000);
});

setInitialValues.watch(() => _setInitialValues());
signInSuccess.watch((data) => _signInSuccess(data));
signOut.watch(() => _signOut());
actionDateRefresh.watch(() => _actionDateRefresh());
sessionRefresh.watch((data) => _sessionRefresh(data));


