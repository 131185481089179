import React from 'react';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';

import './ActionCell.scss';

const ActionCell = (props) => {
  const { record, setSelectedUser, handleUserModalOpen } = props;

  const onClick = (event) => {
    event.stopPropagation();

    handleUserModalOpen();
    setSelectedUser(record);
  };

  return (
    <div className="usersActionCell">
      <div className="actionButton" onClick={onClick}>
        <EllipsisOutlined className="usersActionCellDots" />
      </div>
    </div>
  );
};

export default ActionCell;
