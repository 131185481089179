/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
  required: 'This field is required.',
  types: {
    email: 'Make sure, that email contains sign @ and valid symbols.',
    number: '${label} is not a validate number!',
  },
  string: {
    max: 'This field cannot be longer than ${max} characters.',
  },
};

export const requiredConfig = {
  rules: [
    {
      required: true,
      max: 25,
    },
  ],
};

export const meridiem = 'HH:mm';

export const daysOfTheWeek = [
  { title: 'Monday' },
  { title: 'Tuesday' },
  { title: 'Wednesday' },
  { title: 'Thursday' },
  { title: 'Friday' },
  { title: 'Saturday' },
  { title: 'Sunday' },
];

export const newMode = 'new';

export const editMode = 'edit';

export const hiddenStatuses = {
  rebooked: 'rebooked',
  redirected: 'redirected',
  cancelled: 'cancelled',
  cancelledBlocked: 'cancelled_blocked',

};

export const synonymousUpcomingStatuses = {
  rebooked: 'rebooked',
  redirected: 'redirected',
  booked: 'booked',
};

export const statuses = {
  booked: 'booked',
  idVerified: 'id_verified',
  arrived: 'arrived',
  sampleTaken: 'sample_taken',
  resultsReceived: 'results_received',
  subjectNotified: 'subject_notified',
  cancelled: 'cancelled',
  redirected: 'redirected',
  rebooked: 'rebooked',
  cancelledBlocked: 'cancelled_blocked',
  didNotAttend: 'did_not_attend'
};

export const analysesTypes = {
  covid: 1,
  antibody: 2,
  covidVaccination: 3,
  fluVaccination: 4,
};

export const restrictedAppNowSteps = ['arrived', 'resultsReceived', 'results_received'];
export const restrictedVaccineSteps = ['arrived', 'resultsReceived', 'results_received'];
export const restrictedLaterallySteps = ['arrived', 'results_received', 'resultsReceived'];

export const appointmentTitles = {
  swab: 'Covid-19 Swab Appointment',
  antibody: 'Covid-19 Antibody Appointment',
  flu_vaccine: 'Influenza Vaccination Appointment',
  laterally: 'Lateral Swab Appointment',
  covid_vaccine: 'Covid-19 Vaccination Appointment (Pfizer)',
  covid_vaccine_moderna: 'Covid-19 Vaccination Appointment (Moderna)',
  covid_vaccine_astrazeneca: 'Covid-19 Vaccination Appointment (AstraZeneca)',
  flu_covid_boosted_vaccine: 'Vaccination Appointment',
  ffp3_mask_fitting: "FFP3 Mask Fitting"

};


export const uidAnalyses = {
  swab: 'swab',
  antibody: 'antibody',
  covidVaccine: 'covid_vaccine',
  covidVaccineAstrazeneca: 'covid_vaccine_astrazeneca',
  covidVaccineModerna: 'covid_vaccine_moderna',
  fluVaccine: 'flu_vaccine',
  laterally: 'laterally',
  fluCovidBoostedVaccine: 'flu_covid_boosted_vaccine'
};

export const isCovidVaccineType = (uid) => uid === uidAnalyses.covidVaccine || uid === uidAnalyses.covidVaccineAstrazeneca || uid === uidAnalyses.covidVaccineModerna
export const isFluVaccineType = (uid) => uid === uidAnalyses.fluVaccine  || uid === uidAnalyses.fluCovidBoostedVaccine
export const isBoostedVaccineType = (uid) => uid === uidAnalyses.fluCovidBoostedVaccine

export const covidUid = {
  covidVaccine: "covid_vaccine",
  covidVaccineAstrazeneca: "covid_vaccine_astrazeneca",
  covidVaccineModerna: "covid_vaccine_moderna",
}

export const procedureTypes = {
  analyse: 'analyse',
  vaccine: 'vaccine',
};

export const accountTypes = {
  midessex: 'mid-essex',
  norfolk: 'norfolk',
  wessex: 'wessex',
  commisceo: 'commisceo',
  xyz:'xyz',
  EPUT:'EPUT',
  Yorkshire : "Yorkshire",
};

export const numberOfNewAppointments = 3;
export const defaultNumberOfAppointments = 2;

export const defaultNumberOfUserAppointments = 5;
export const defaultNumberOfLoadingAppointments = 10;

export const defaultHospitalsPaginationSize = 6;

export const covidVaccinationConsentCheckboxes = [
  { title: 'Patient does not have any symptoms or signs of a systemic infection or symptoms of COVID -19 infection.', restrictions: {} },
  {
    title: 'Patient has not had any vaccinations in the last 7 days.', restrictions: {
      uidAnalyse: [uidAnalyses.covidVaccine, uidAnalyses.covidVaccineAstrazeneca, uidAnalyses.covidVaccineModerna],
    }
  },
  { title: 'Patient does not have an outstanding COVID swab result or COVID positive result in last 28 days.', restrictions: {} },
  {
    title: 'The patient has verbally confirmed they are not pregnant.', restrictions: {
      gender: ['male'],
    }
  },
];

export const checklistMessages = {
  cohortOccupational: 'Cohort Occupational Health Record (Optional)',
  electronicStaff: 'Electronic Staff Record (Optional)',
  anaphylacticReaction: 'The patient does not have a confirmed anaphylactic reaction to a previous dose of the flu vaccine.',
  eggsAllergy: 'The patient does not have an allergy to eggs, egg products or products made from chicken.',
  bleeding: 'The patient does not have a bleeding disorder nor take any medication that could affect my blood clotting e.g. warfarin.',
  statement: 'Patient does not have any symptoms or signs of a systemic infection, e.g. fever.',
  left: 'Left',
  right: 'Right',
  routeOfInjection: 'IM',
};

export const procedureInfoCombinedTitles = {
  covid: 'Covid booster',
  flu : 'Influenza vaccine'
}

export const declinedReasons = [
  { reason: 'empty' },
  { reason: 'Already had the flu vaccine for Sept’20 – Feb’21 season, or intend to have the Flu vaccine at your GP.' },
  { reason: 'Unable due to contraindication.' },
  { reason: 'Refused to get the vaccination.' }];

export const usersTableVisibleParams = {
  all: 'all',
  patients: 'patients',
  staff: 'staff',
}
