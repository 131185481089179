import React from 'react'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, chunkError: false };
    }
  
    static getDerivedStateFromError(error) {
        const parsedError = JSON.parse(JSON.stringify(error))
        if(parsedError?.name === "ChunkLoadError"){
            return { chunkError: true };
        }
         return { hasError: true };
    }
  
    render() {
      if (this.state.hasError) {
        return this.props?.internalServer;
      } if (this.state.chunkError){
        return this.props?.maintenance;
      }
  
      return this.props.children; 
    }
  }