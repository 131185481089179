import { createStore, createEffect, createEvent } from 'effector';
import { createDebounce } from 'effector-debounce';
import { notification } from 'antd';
import { snakeCase, camelCase } from 'change-case';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import uniqueId from 'lodash/uniqueId';

import { client } from '../../helpers/http';
import moment from 'moment';

export const $isLoading = createStore(false);
export const $hospitalLoading = createStore(false);
export const $organisationLoading = createStore(false);
export const $users = createStore([]);
// added bellow line
export const $appointments = createStore([]);
export const $analyses = createStore([]);
export const $status = createStore([]);
export const $date = createStore('');
export const $Enddate = createStore('');
export const $haveAppointment=createStore(false);
// export const $organisationLoading = createStore(false);

export const $hospitals = createStore([]);
export const $organisations = createStore([]);
export const $hospitalsPagination = createStore({
  total: null,
  current: 1,
  pageSize: 20,
  count: null,
});
export const $organisationsPagination = createStore({
  total: null,
  current: 1,
  pageSize: 20,
  count: null,
});
export const $hospitalsMap = createStore([]);
export const $roles = createStore([]);
export const $rolesMap = createStore([]);
export const $configs = createStore({});
export const $search = createStore('');
export const $isChangeUserModalOpen = createStore(false);
export const $selectedUser = createStore({});
export const $selectedUserRoles = createStore([]);
export const $selectedUserHospitals = createStore([]);
export const $isStaffFilter = createStore(false);
export const $selectedRow = createStore([]);
export const $isDisabledAppointment = createStore(true);
export const $verificationInProgress = createStore(false);
export const $consentApprovalFilter = createStore(true);
export const $activeTab = createStore('singular');
export const $isModalVisible = createStore(false);
export const $selectedAction = createStore('activate');
export const $isNotificationOpen = createStore(false);
export const $notificationTimer = createStore(null);
export const $notificationTimeLeft = createStore(null);
export const $notificationChanges = createStore(null);


export const $pagination = createStore({
  total: null,
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  size: 'small',
});
export const $filters = createStore({
  hospitals: [],
  organisations: [],
  roles: [],
});
// added bellow line
export const $vaccinationFilters = createStore({
  analyses: [],
  status: [],

});

export const initPageFetch = createEvent();
export const fetchUsers = createEvent();
// added bellow line
export const fetchAppointments = createEvent();
export const changeStatus = createEvent();
export const changeAnalyses = createEvent();
export const changeDate = createEvent();
export const changeEndDate= createEvent();
export const changeAppointment = createEvent();
export const fetchHospitals = createEvent();
export const fetchOrganisations = createEvent();
export const fetchConfigs = createEvent();
export const changePagination = createEvent();
export const updateUser = createEvent();
export const changeFilters = createEvent();
export const clearFilters = createEvent();
export const changeSearch = createEvent();
export const clearSearch = createEvent();
export const handleUserModalOpen = createEvent();
export const setSelectedUser = createEvent();
export const changeUserRoles = createEvent();
export const chagneUserHospitals = createEvent();
export const changeIsStaffFilter = createEvent();
export const setIsStaffFilter = createEvent();
export const changeSelectedRow = createEvent();
export const changeVerificationInProgress = createEvent();
export const changeConsentApprovalFilter = createEvent();
export const changeActiveTab = createEvent();
export const activateUser = createEvent();
export const deleteUser = createEvent();
export const setModalVisible = createEvent();
export const setSelectedAction = createEvent();
export const resetPagination = createEvent();
export const resetTabs = createEvent();
export const resetUsers = createEvent();
export const setHospitalsPagination = createEvent();
export const setOrganisationsPagination = createEvent();
export const resetHospitalsPagination = createEvent();
export const resetOrganisationsPagination = createEvent();
export const setNotificationOpen = createEvent();
export const changeNotificationTimer = createEvent();
export const changeNotificationTimeLeft = createEvent();
export const resendInvite = createEvent();

const onMessage = createEvent('message') // named event

export const deleteSearchItem = createEvent();

const changeSearchDebounce = createDebounce(changeSearch, 500);
const changeStatusDebounce = createDebounce(changeStatus, 500);
const changeAnalysesDebounce = createDebounce(changeAnalyses, 500);
const changeDateDebounce = createDebounce(changeDate, 500);
const changeEndDateDebounce = createDebounce(changeEndDate, 500);
const changeAppointmentDebounce = createDebounce(changeAppointment, 500);

const _fetchUsers = createEffect();
// added below line
const _fetchAppointments = createEffect();

const _fetchHospitals = createEffect();
const _fetchOrganisations = createEffect();
const _fetchConfigs = createEffect();
const _updateUser = createEffect();
const _activateUser = createEffect();
const _deleteUser = createEffect();
const _resendInvite = createEffect();

_resendInvite.use((email) => client
    .post("/api/v1/invitations/resend-invite/?management+true",{email})
    .then(() => {
      notification.success({message: "Invitation email sent"})
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    }));

_deleteUser.use(({userId}) => client
    .delete(`/api/v1/users/${userId}/?management=true`)
    .then(() => {
      notification.success({ message: 'User deleted!' });
      fetchUsers();
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    }))

_activateUser.use(({userId}) => {
  const options = {
    duplicateCheckStatus: 'succeeded',
    };

  return client
    .patch(`/api/v1/users/${userId}/?management=true`, options)
    .then(() => {
      notification.success({ message: 'User information is updated.' });
      fetchUsers();
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    });
})

_fetchHospitals.use(async () => {
  const pagination = $hospitalsPagination.getState();
  const hospitals = $hospitals.getState();
  const options = {
    params: {
      ordering: 'title',
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      archived: false,
      management: true,
    },
  };

  if (pagination?.count > pagination?.total || !pagination?.total) {
    const response = await client.get('/api/v1/hospitals/', {
      ...options,
    })
      .catch((error) => {
        const message = get(error, 'message', '');
        notification.error({ message });
      });

    setHospitalsPagination({
      ...pagination,
      current: pagination.current + 1,
      total: pagination.total + get(response, 'data.results', []).length,
      count: get(response, 'data.count', null),
    })

    return pagination.current === 1 ? get(response, 'data.results', []) : [...hospitals, ...get(response, 'data.results', [])];
  }
});

_fetchOrganisations.use(async () => {
  const pagination = $organisationsPagination.getState();
  const organisations = $organisations.getState();
  const options = {
    params: {
      ordering: 'title',
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    },
  };

  if (pagination?.count > pagination?.total || !pagination?.total) {
    const response = await client.get('/api/v1/organizations/', {
      ...options,
    })
      .catch((error) => {
        const message = get(error, 'message', '');
        notification.error({ message });
      });

    setOrganisationsPagination({
      ...pagination,
      current: pagination.current + 1,
      total: pagination.total + get(response, 'data.results', []).length,
      count: get(response, 'data.count', null),
    })

    return pagination.current === 1 ? get(response, 'data.results', []) : [...organisations, ...get(response, 'data.results', [])];
  }
});

_fetchConfigs.use(() => client
  .get('/api/v1/configs/')
  .then((response) => get(response, 'data', {}))
  .catch((error) => {
    const message = get(error, 'message', '');
    notification.error({ message });
  }));

_fetchUsers.use(() => {
  const analyse = $analyses.getState();
  const status = $status.getState();
  console.log("status is",status);
  const date = $date.getState();
  const Enddate = $Enddate.getState();
  const haveAppointment = $haveAppointment.getState();
  console.log("haveAppointment is",haveAppointment);
  const pagination = $pagination.getState();
  const filters = $filters.getState();
  const search = $search.getState();
  const isStaffFilter = $isStaffFilter.getState();
  const isVerificationInProgress = $verificationInProgress.getState();
  const consentApprovalFilter = $consentApprovalFilter.getState();
  const tab = $activeTab.getState();
  const { hospitals, organisations, roles } = filters;
  const verifications = isVerificationInProgress
    ? ['in_progress']
    : ['verified', 'in_progress','not_verified'];

  const options = {
    params: {
      verifications,
      management: true,
      // ordering: 'first_name',
      is_staff: isStaffFilter,
      consent_approval: consentApprovalFilter ? '' : false,
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      hospitals,
      organizations: organisations,
      duplicateCheckStatuses: tab === 'duplicated' ? ['blocked'] : ['in_progress','succeeded'] ,
      roles: roles.map((role) => isStaffFilter?snakeCase(role):role),
      search,
      analyse:analyse,
      status:status,
      date:date?date:'None',
      Enddate:Enddate?Enddate:'None',
      haveAppointment:haveAppointment,
      tab:tab,
    },
  };

  return client
    .get('/api/v1/get_users/', options)
    .then((response) => {
      console.log("response is",response);
      const data = {
        count: get(response, 'data.count', null),
        users: get(response, 'data.results', []).map((user) => ({
          ...user,
          role: user.role.map((r) => camelCase(r)),
        })),
      };
      console.log("data is",response);

      return data;
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    });
});

// added below event
_fetchAppointments.use(() => {
  const pagination = $pagination.getState();
  const analyse = $analyses.getState();
  const status = $status.getState();
  console.log("statysh22222222222",status);
  // const search = $search.getState();
  // const isStaffFilter = $isStaffFilter.getState();
  // const isVerificationInProgress = $verificationInProgress.getState();
  // const consentApprovalFilter = $consentApprovalFilter.getState();
  // const tab = $activeTab.getState();
  // const { analyses, status } = filters;
  // const verifications = isVerificationInProgress
  //   ? ['in_progress']
  //   : ['verified', 'in_progress','not_verified'];

  const options = {
    params: {
      // verifications,
      management: true,
      // ordering: 'first_name',
      // is_staff: isStaffFilter,
      // consent_approval: consentApprovalFilter ? '' : false,
      // offset: (pagination.current - 1) * pagination.pageSize,
      // limit: pagination.pageSize,
      limit:status.length>0?500000000:0,
      // hospitals,
      // organizations: organisations,
      // duplicateCheckStatuses: tab === 'duplicated' ? ['blocked'] : ['in_progress','succeeded'] ,
      status_in:status.length>0||analyse.length>0?status:'status',
      analyse_in:analyse.length>0?analyse:null
      // search,
    },
  };



  return client
    .get('/api/v1/usersCustom/',options)
    .then((response) => {
      const data = {
        count: get(response, 'data.count', null),
        appointments: get(response, 'data.results', []).map((appointment) => ({
          ...appointment,
          // role: user.role.map((r) => camelCase(r)),
        })),
      };


      return data;
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    });
});

// end




_updateUser.use(() => {
  const selectedUser = $selectedUser.getState();
  const selectedUserRoles = $selectedUserRoles.getState();
  const selectedUserHospitals = $selectedUserHospitals.getState();

  const options = {
    role: selectedUserRoles.map((role) => snakeCase(role)),
    hospitals: selectedUserHospitals.map((hospital) => ({ id: hospital })),
  };

  return client
    .patch(`/api/v1/users/${selectedUser.id}/?management=true`, options)
    .then(() => {
      notification.success({ message: 'User information is confirmed.' });
      handleUserModalOpen();
      fetchUsers();
    })
    .catch((error) => {
      const message = get(error, 'message', '');
      notification.error({ message });
    });
});

$hospitals.on(_fetchHospitals.done, (_, { result }) => result);
$hospitalsMap.on($hospitals, (_, params) => {
  const hospitalsMap = {};
  params.forEach((hospital) => {
    hospitalsMap[hospital.id] = hospital.title;
  });

  return hospitalsMap;
});
$organisations.on(_fetchOrganisations.done, (_, { result }) => result);
$configs.on(_fetchConfigs.done, (_, { result }) => result);
$roles.on(_fetchConfigs.done, (_, { result }) => {
  const managerRoles = get(result, 'managerRoles', {});
  const roles = keys(managerRoles).map((key) => ({
    key,
    title: managerRoles[key],
    id: uniqueId(),
  }));
  return roles;
});
$rolesMap.on($roles, (_, params) => {
  const rolesMap = {};
  params.forEach((role) => {
    rolesMap[role.key] = role.title;
  });
  return rolesMap;
});
$users
  .on(_fetchUsers.done, (_, { result }) => result.users)
  .reset(resetUsers);

  // added below
  $appointments
  .on(_fetchAppointments.done, (_, { result }) => result.appointments)
  // .reset(resetUsers);
  // end

$isChangeUserModalOpen.on(handleUserModalOpen, (state) => !state);
$verificationInProgress
  .on(changeVerificationInProgress, (state) => !state)
  .reset(clearFilters);
$selectedUser.on(setSelectedUser, (_, props) => props);
$selectedAction.on(setSelectedAction, (_, params) => params);
$consentApprovalFilter
  .on(changeConsentApprovalFilter, (state) => !state)
  .reset(clearFilters);

$pagination
  .on(changePagination, (_, params) => params)
  .on(
    [
      $filters,
      // added
      $vaccinationFilters,
      // ens
      $search,
      $isStaffFilter,
      $verificationInProgress,
      $consentApprovalFilter,
       $status,
       $analyses,
       $date,
       $Enddate,
       $haveAppointment,
    ],
    (state) => ({
      ...state,
      current: 1,
    }),
  )
  .on(_fetchUsers.done, (state, { result }) => ({
    ...state,
    total: result.count,
  }))
  .on(changeAppointment, (state) => ({
    ...state,
    current: 1,
  }))

  .on(changeStatus, (state) => ({
    ...state,
    current: 1,
  }))
  .on(changeAnalyses, (state) => ({
    ...state,
    current: 1,
  }))
  .on(changeDate, (state) => ({
    ...state,
    current: 1,
  }))
  .on(changeEndDate, (state) => ({
    ...state,
    current: 1,
  }))


  // .reset(resetPagination)

  // added
  .on(_fetchAppointments.done, (state, { result }) => ({
    ...state,
    total: result.count,
  }))
  .reset(resetPagination);
  // end
$filters
  .on(changeFilters, (state, params) => {
    const { key, value } = params;

    return {
      ...state,
      [key]: value,
    };
  })
  .reset(clearFilters);

  // start
  $vaccinationFilters
  .on(changeFilters, (state, params) => {
    const { key, value } = params;

    return {
      ...state,
      [key]: value,
    };
  })
  .reset(clearFilters);
  // end

$notificationTimer
  .on(changeNotificationTimer, (_, params) => {
    const { time, disable } = params;
    let updateTimer;
    if (!disable) {
      updateTimer = setTimeout(() => {
        fetchHospitals();
        fetchOrganisations();
        fetchUsers();
        // add
        // fetchAppointments();
        // end
        setNotificationOpen(false);
      }, time);
    } else {
      clearTimeout(updateTimer);
      setNotificationOpen(false);
    }

    return params;
  });
$notificationTimeLeft
  .on(changeNotificationTimeLeft, (_, params) => params);

$isStaffFilter.on(changeIsStaffFilter, (state) => !state).reset(clearFilters);
$isStaffFilter.on(setIsStaffFilter, (_, params) => params).reset(clearFilters);
$status.on(changeStatus, (_, params) => params).reset(clearSearch);
$analyses.on(changeAnalyses, (_, params) => params).reset(clearSearch);
$date.on(changeDate, (_, params) => params).reset(clearSearch);
$Enddate.on(changeEndDate, (_, params) => params).reset(clearSearch);
$haveAppointment.on(changeAppointment, (_, params) => params).reset(clearSearch);

$search
  .on(changeSearch, (_, params) => params)
  .reset(clearSearch)
  .reset(deleteSearchItem);
$selectedUserRoles
  .on(changeUserRoles, (_, params) => params)
  .on(setSelectedUser, () => {
    const user = $selectedUser.getState();
    const roles = $roles.getState();
    const userRoles = user.role;
    const defaultRoles = roles.filter((role) => userRoles?.includes?.(role.key));

    return defaultRoles.map((role) => role.key);
  });
$selectedUserHospitals
  .on(chagneUserHospitals, (_, params) => params)
  .on(setSelectedUser, () => {
    const user = $selectedUser.getState();
    return user.hospitals.map((hospital) => hospital.id);
  });

$selectedRow
  .on(changeSelectedRow, (_, params) =>
    (last(params) ? [last(params)] : []))
  .on(deleteSearchItem, () => [])
  .reset(clearFilters);

$isModalVisible.on(setModalVisible, (_, params) => params);
$isDisabledAppointment.on($selectedRow, (_, params) => isEmpty(params));
$activeTab
  .on(changeActiveTab, (_, params) => params)
  .reset(resetTabs);
$hospitalsPagination
  .on(setHospitalsPagination, (_, pagination) => pagination)
  .reset(resetHospitalsPagination);
$organisationsPagination
  .on(setOrganisationsPagination, (_, pagination) => pagination)
  .reset(resetOrganisationsPagination);
$isNotificationOpen
  .on(setNotificationOpen, (_, params) => params);

changeIsStaffFilter.watch(() => _fetchUsers());
setIsStaffFilter.watch(() => _fetchUsers());
changePagination.watch(() => _fetchUsers());
changeFilters.watch(() => _fetchUsers());
// add
// changePagination.watch(() => _fetchAppointments());
// changeFilters.watch(() => _fetchAppointments());
// end
clearFilters.watch(() => {
  clearSearch();
});
changeActiveTab.watch(() => {
  resetPagination();
  clearFilters();
})
clearSearch.watch(() => _fetchUsers());
changeSearchDebounce.watch(() => _fetchUsers());
changeVerificationInProgress.watch(() => _fetchUsers());
changeConsentApprovalFilter.watch(() => _fetchUsers());

fetchHospitals.watch(() => _fetchHospitals());
fetchOrganisations.watch(() => _fetchOrganisations());
fetchConfigs.watch(() => _fetchConfigs());
fetchUsers.watch(() => _fetchUsers());
// add
fetchAppointments.watch(() => _fetchAppointments());
changeStatusDebounce.watch(() => _fetchUsers());
changeAnalysesDebounce.watch(() => _fetchUsers());
changeDateDebounce.watch(() => _fetchUsers());
changeEndDateDebounce.watch(() => _fetchUsers());
changeAppointmentDebounce.watch(() => _fetchUsers());

// end
updateUser.watch(() => _updateUser());
activateUser.watch((data) => _activateUser(data));
deleteUser.watch((data) => _deleteUser(data));
resendInvite.watch((data) => _resendInvite(data));

initPageFetch.watch(() => {
  resetUsers();
  clearFilters();
  // _fetchHospitals();
  _fetchOrganisations();
  _fetchConfigs();
  _fetchUsers();
  // add
  // _fetchAppointments();
  // end
});

$isLoading
  .on(_activateUser, () => true)
  .on(_activateUser.done, () => false)
  .on(_activateUser.fail, () => false)
  .on(_deleteUser, () => true)
  .on(_deleteUser.done, () => false)
  .on(_deleteUser.fail, () => false)
  .on(_fetchUsers, () => true)
  .on(_fetchUsers.done, () => false)
  .on(_fetchUsers.fail, () => false)
  // add
  .on(_fetchAppointments, () => true)
  .on(_fetchAppointments.done, () => false)
  .on(_fetchAppointments.fail, () => false)
  // end
  .on(_fetchHospitals, () => true)
  .on(_fetchHospitals.done, () => false)
  .on(_fetchHospitals.fail, () => false)
  .on(_fetchOrganisations, () => true)
  .on(_fetchOrganisations.done, () => false)
  .on(_fetchOrganisations.fail, () => false)
  .on(_fetchConfigs, () => true)
  .on(_fetchConfigs.done, () => false)
  .on(_fetchConfigs.fail, () => false)
  .on(_updateUser, () => true)
  .on(_updateUser.done, () => false)
  .on(_updateUser.fail, () => false);

$hospitalLoading
  .on(_fetchHospitals, () => true)
  .on(_fetchHospitals.done, () => false);

$organisationLoading
  .on(_fetchOrganisations, () => true)
  .on(_fetchOrganisations.done, () => false);
