import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import "./index.scss";

function CustomMenu({ menuItems }) {
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      className="CustomMenu"
      style={{ background: "transparent" }}
      selectedKeys={menuItems
        .filter((item) => item.active)
        .map((item) => item.title)}
    >
      {menuItems.map((item) => (
        <Menu.Item key={item.title}>
          <NavLink isActive={(match, location) => {
            if (location?.state?.fromUsers) {
              return true;
            }
            return match;

          }}
             activeClassName="Menu_Item-Active" to={item.to}>
            {item.title}
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default CustomMenu;
