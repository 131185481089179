import { useStore } from 'effector-react';

import { $currentUser } from '../models';
import { $account } from '../models/auth';

import { accountTypes } from './constants';

const MultiTenancyHoc = (props) => {
  const {
    allowedRegions, ...component
  } = props;
  const currentUser = useStore($currentUser);
  const accounts = useStore($account);

  const currentAccount = currentUser?.account;

  if (allowedRegions?.includes?.(currentAccount?.uid)) {
    return component.children;
  }

  if (currentUser.account === undefined) {
    const isSubdomain = window.location.origin.split('.').length > 2;

    // added new map url condition
    let str=window.location.href;
    var map_url = str.split('/').slice(0,4).join('/')
    console.log(map_url)
    // end new map url condition

    // commented below line for map url
    // const findedAccount = accounts?.find((account)=>account?.subdomain === window.location.origin)
    const findedAccount = accounts?.find((account)=>account?.subdomain === map_url)

    if (isSubdomain && allowedRegions?.includes?.(findedAccount?.uid)) {
      return component.children;
    }

    if (!isSubdomain && (allowedRegions?.includes?.(accountTypes.midessex) || allowedRegions?.includes?.(accountTypes.norfolk))) {
      // added eput trust on above line
      return component.children;
    }

  }

  return null;
};

export default MultiTenancyHoc;
