import _ from 'lodash';
import { useMemo } from 'react';

export const useFilteredChecklist = (answersScheme, patient) => useMemo(
  () => ({
    ...answersScheme,
    checklist: _.map(answersScheme?.checklist, (question) => ({
      ...question,
      answers: _.filter(question?.answers, (answer) => (_.matches(answer.condition)(patient)) || (answer.condition?.hasOwnProperty('genders') && answer.condition?.genders?.includes(patient?.gender))),
    })),
  }),
  [answersScheme, patient],
);

export default useFilteredChecklist;
