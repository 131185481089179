import { client } from '../../helpers/http';

export const getAccounts = async () => client.get('/api/v1/accounts/');

export const getAccountsForSignUp = async () => client.get('/api/v1/accounts/?ordering=created');

export const postSignIn = async (data) => client.post('/api/v1/token/obtain/', data);

export const refreshToken = async (refresh) => client.post('/api/v1/token/refresh/', refresh);

export const postSignUp = (data) => client.post('/api/v1/register/', data);

export const postRegistration = async (data) => client.post('/api/v1/verify_registration/', data);

export const postForgotPassword = (data) => client.post('/api/v1/forgot_password/', data);

export const postResendEmail = (data) => client.post('/api/v1/resend/', data);

export const postResetPassword = (data) => client.post('/api/v1/reset_password/', data);

export const postChangePassword = (data) => client.patch('/api/v1/change/password/', data);
