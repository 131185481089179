import React from 'react';
import { createComponent } from 'effector-react';
import { withRouter } from 'react-router-dom';

import App from './App';
import {
  $currentUser,
  $isMaintenanceMode,
  $isInternalServerError,
} from './models';
import {
  $decodedToken,
  $refreshExp,
  $accessExp,
  $accessGained,
  $refreshToken,
  $lastActionDate,
  signOut
} from './models/auth';

const AppContainer = createComponent(
  {
    $decodedToken,
    $refreshExp,
    $accessExp,
    $accessGained,
    $refreshToken,
    $lastActionDate,
    $currentUser,
    $isMaintenanceMode,
    $isInternalServerError,
  },
  (props, state) => (
    <App
      {...props}
      currentUser={state.$currentUser}
      decodedToken={state.$decodedToken}
      refreshExp={state.$refreshExp}
      accessExp={state.$accessExp}
      accessGained={state.$accessGained}
      refreshToken={state.$refreshToken}
      lastActionDate={state.$lastActionDate}
      profile={state.$currentUser}
      isMaintenanceMode={state.$isMaintenanceMode}
      isInternalServerError={state.$isInternalServerError}
      signOut={signOut}
    />
  ),
);

export default withRouter(AppContainer);
