import React from "react";
import "./index.scss";

import { Button } from "antd";

function ButtonComponent({ children, ...rest }) {
  return (
    <div className="Button-Container">
      <Button {...rest}>{children}</Button>
    </div>
  );
}

export default ButtonComponent;
