/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense } from 'react';
// import ReactGA from 'react-ga';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import { Header, Footer, ErrorBoundary } from './containers';
import { ResendNotification } from './components'
import { accountTypes } from './helpers/constants';
import Maintenance from './pages/ErrorPages/Maintenance'
import InternalServer from './pages/ErrorPages/InternalServer'

import './App.scss';
import './scrollBar.scss';
import './PrintMedia.scss';

const { Content } = Layout;

// const initGA = async () => {
//   ReactGA.initialize('UA-166755016-2', {
//     debug: true,
//     gaOptions: { address: '/collect' },
//   });
// };

// const setNewUserAfterLoginGA = (userid) => {
//   ReactGA.set({ userId: userid });
// };

const LazySignUp = lazy(() =>
  import(/* webpackChunkName: "signUp" */ './pages/SignUp'),
);
const LazySignIn = lazy(() =>
  import(/* webpackChunkName: "signIn" */ './pages/SignIn'),
);
const LazyBlocked = lazy(() =>
  import(/* webpackChunkName: "signIn" */ './pages/CurrentUserBlocked'),
);
const LazyPersonalProfile = lazy(() =>
  import(/* webpackChunkName: "personalProfile" */ './pages/PersonalProfile'),
);
const LazyRegistered = lazy(() =>
  import(/* webpackChunkName: "registered" */ './pages/Registered'),
);
const LazyConfirmedAppointment = lazy(() =>
  import(
    /* webpackChunkName: "appointmentConfirmed" */ './pages/Appointments/container/AppointmentConfirmed'
  ),
);
const LazyNewAppointment = lazy(() =>
  import(
    /* webpackChunkName: "newAppointment" */ './pages/Appointments/container/NewAppointment'
  ),
);
const LazySchedule = lazy(() =>
  import(
    /* webpackChunkName: "schedule" */ './pages/Appointments/container/Schedule/index'
  ),
);
const LazyAppointments = lazy(() =>
  import(/* webpackChunkName: "appointments" */ './pages/Appointments'),
);
const LazyCreateUserProfile = lazy(() =>
  import(/* webpackChunkName: "createUserProfile" */ './pages/CreateUserProfile/CreateUserProfileContainer'),
)
const LazyCreateNewUserProfile = lazy(() =>
  import(/* webpackChunkName: "newUserProfile" */ './pages/CreateUserProfile/NewProfile'),
)
const LazyForgot = lazy(() =>
  import(/* webpackChunkName: "forgot" */ './pages/Forgot'),
);

const LazyResendEmail = lazy(() =>
  import(/* webpackChunkName: "resendEmail" */ './pages/Forgot'),
);

const LazyForgotSuccess = lazy(() =>
  import(/* webpackChunkName: "forgotSuccess" */ './pages/ForgotSuccess'),
);
const LazyCreateNewPassword = lazy(() =>
  import(
    /* webpackChunkName: "createNewPassword" */ './pages/CreateNewPassword'
  ),
);
const LazyPrivacy = lazy(() =>
  import(/* webpackChunkName: "privacy" */ './pages/Privacy'),
);
const LazyTerms = lazy(() =>
  import(/* webpackChunkName: "terms" */ './pages/Terms'),
);
const LazyChangePassword = lazy(() =>
  import(/* webpackChunkName: "changePassword" */ './pages/ChangePassword'),
);
const LazyScheduleGenerator = lazy(() =>
  import(
    /* webpackChunkName: "acheduleGenerator" */ './pages/ScheduleGenerator'
  ),
);
const LazyNewSchedule = lazy(() =>
  import(
    /* webpackChunkName: "newSchedule" */ './pages/ScheduleGenerator/newSchedule'
  ),
);

const LazyReports = lazy(() =>
  import(
    /* webpackChunkName: "reports" */ './pages/Reports'
    ),
);

// adding Send Communication
const LazySendCommunication = lazy(() =>
  import(
    /* webpackChunkName: "SendCommunication" */ './pages/SendCommunication'
    ),
);

// adding User Segmentation
const LazyUserSegmentTest = lazy(() =>
  import(
    /* webpackChunkName: "user-segment" */ './pages/UserSegmentTest'
    ),
);
// end user segmentation

const LazyUsers = lazy(() =>
  import(/* webpackChunkName: "users" */ './pages/Users'),
);
const LazyUser = lazy(() =>
  import(/* webpackChunkName: "user" */ './pages/User'),
);
const LazyUrgentAppointment = lazy(() =>
  import(
    /* webpackChunkName: "urgentAppointment" */ './pages/Appointments/container/UrgentAppointment'
  ),
);
const LazyOtherSettings = lazy(() =>
  import(/* webpackChunkName: "otherSettings" */ './pages/OtherSettings'),
);
const LazyViewCases = lazy(() =>
  import(/* webpackChunkName: "viewCases" */ './pages/ViewCases'),
);
const LazyСonsentApproval = lazy(() =>
  import(/* webpackChunkName: "consentApproval" */ './pages/СonsentApproval'),
);
const LazyInternalServer = lazy(() =>
  import(
    /* webpackChunkName: "internalServer" */ './pages/ErrorPages/InternalServer'
  ),
);
const LazyMaintenance = lazy(() =>
  import(
    /* webpackChunkName: "maintenance" */ './pages/ErrorPages/Maintenance'
  ),
);
const LazyAppointmentDetails = lazy(() =>
  import(
    /* webpackChunkName: "appointmentDetails" */ './pages/AppointmentDetails'
  ),
);
const LazyAnalytics = lazy(() =>
  import(/* webpackChunkName: "analytics" */ './pages/Analytics'),
);
const LazyAdverseEffects = lazy(() =>
  import(/* webpackChunkName: "adverseEffects" */ './pages/AdverseEffects'),
);

const AuthRoutes = (props) => {
  // console.log("props lacation is",props.location);
  const userSignedUpBefore = localStorage.getItem('signedIn');
  const userBlocked = localStorage.getItem('blocked');
  const userIsBlocked = userBlocked === "true"
  return (
    <Switch>
      <Route exact path="/blocked" component={LazyBlocked} />
      <Route path="/sign-up" component={LazySignUp} />
      <Route path="/sign-in" component={LazySignIn} />
      <Route path="/forgot" component={LazyForgot} />
      <Route path="/resend-email" component={LazyResendEmail} />

      <Route key="terms" path="/terms" component={LazyTerms} />
      <Route key="privacy" path="/privacy" component={LazyPrivacy} />

      <Route path="/registered" component={LazyRegistered} />
      <Route path="/forgot-success" component={LazyForgotSuccess} />
      <Route path="/reset" component={LazyCreateNewPassword} />
      <Redirect to={userIsBlocked ? '/blocked' : userSignedUpBefore ? '/sign-in' : '/sign-up'} />
    </Switch>
  );
};


const PrivateRoutes = (props) => {
  const { user, location, signOut, history } = props;
  const role = user?.role;
  const isStage = window.location.href.includes('covid-stage');
  const isStaff = user?.isStaff;
  const isFilled = user?.isFilled;
  const isstaff = user?.staff;
  const isSuperUser = user?.role?.includes?.('super');
  const isUrgentManager = user?.role?.includes?.('call_centre');
  const isTestCenterManager = user?.role?.includes?.('test_centre');
  const isMSE = user?.account?.uid === accountTypes.midessex;
  const isCommisceo = user?.account?.uid === accountTypes.commisceo;
  const isCheck = user?.account?.uid;
  const isEPUT = user?.account?.uid === accountTypes.EPUT;
  const isProdENV = process.env.REACT_APP_ENV === 'master';
  // console.log("user",user);
  // console.log("isFilled",isFilled);
  // console.log("location is",location);
  // console.log("history is",history);

  if (isFilled === undefined) {
    return null;
  }

  if (user.forcePasswordChange && location.pathname !== '/change-password') {
    return (
      <Redirect
        to={{
          pathname: '/change-password',
          state: { from: location },
        }}
      />
    );
  }



  // console.log("find",location);
  // if(location?.)


  return (
    <Switch>
      {!isUrgentManager && (
        <Route exact path="/appointments" component={LazyAppointments} />
      )}
      {isSuperUser && (isMSE||isEPUT) && isStage && (
        <Route exact path="/create-user-profile" component={LazyCreateUserProfile} />
      )}
      {isSuperUser && isStage && (
        <Route exact path="/create-user-profile/new" component={LazyCreateNewUserProfile} />
      )}
      <Route path="/profile" component={LazyPersonalProfile} />
      {!isUrgentManager && (
        <Route
          exact
          path="/appointments/:id/confirmed"
          component={LazyConfirmedAppointment}
        />
      )}
      {!isUrgentManager && (
        <Route
          exact
          path="/appointments/:id/edit/confirmed"
          component={LazyConfirmedAppointment}
        />
      )}
      {isStaff && isSuperUser && !isUrgentManager && (
        <Route exact path="/schedule" component={LazyScheduleGenerator} />
      )}
      {role !== undefined && isSuperUser && !isUrgentManager && (
        <Route
          exact
          path="/schedule/new/:centreId"
          component={LazyNewSchedule}
        />
      )}

      {!isProdENV && isSuperUser && !isUrgentManager && (
        <Route exact path="/analytics" component={LazyAnalytics} />
      )}

      {role !== undefined && isSuperUser && !isUrgentManager && (
        <Route
          exact
          path="/schedule/edit/:scheduleId"
          component={LazyNewSchedule}
        />
      )}
      {!isUrgentManager && (
        <Route exact path="/appointments/new" component={LazyNewAppointment} />
      )}
      {!isUrgentManager && (
        <Route
          exact
          path="/appointments/edit/:id"
          component={LazyNewAppointment}
        />
      )}
      {!isUrgentManager && (
        <Route exact path="/appointments/schedule" component={LazySchedule} />
      )}
      {!isUrgentManager && (
        <Route
          exact
          path="/appointments/schedule/edit/:id"
          component={LazySchedule}
        />
      )}
      {isStaff && !isUrgentManager && (
        <Route exact path="/manager/appointments" component={LazyViewCases} />
      )}
      {!isUrgentManager && (
        <Route
          exact
          path="/manager/appointments/:id"
          component={LazyAppointmentDetails}
        />
      )}
      {isSuperUser && !isUrgentManager && (
        <Route path="/other-settings" component={LazyOtherSettings} />
      )}
      {isSuperUser && !isUrgentManager && (
        <Route path="/reports" component={LazyReports} />
      )}

      {/* added send communication */}

        <Route path="/SendCommunication/" component={LazySendCommunication} />



        <Route path="/SendCommunication/no" component={LazySendCommunication} />



        <Route path="/SendCommunication/can't" component={LazySendCommunication} />


      {isSuperUser && !isUrgentManager && (
        <Route path="/user-segment" component={LazyUserSegmentTest} />
      )}

      {!isUrgentManager && (
        <Route
          exact
          path="/consent-approval/:id"
          component={LazyСonsentApproval}
        />
      )}
      {!isUrgentManager && (
        <Route
          exact
          path="/consent-approval/:id/vaccinating"
          component={LazyСonsentApproval}
        />
      )}
      {!isUrgentManager && (
        <Route
          exact
          path="/adverse-effects/:id"
          component={LazyAdverseEffects}
        />
      )}
      {(isStaff || isSuperUser) && !isUrgentManager && <Route exact path="/users" component={LazyUsers} />}
      {((isCheck) ? (isUrgentManager || isTestCenterManager || isSuperUser) : isUrgentManager) && (
        <Route
          exact
          path="/book-appointment"
          component={LazyUrgentAppointment}
        />
      )}
      {!isUrgentManager && <Route exact path="/users/user/:id" component={LazyUser} />}
      <Route exact path="/change-password" component={LazyChangePassword} />

      <Route key="terms" path="/terms" component={LazyTerms} />
      <Route key="privacy" path="/privacy" component={LazyPrivacy} />

      <Redirect
        to={
          isFilled === true && (isstaff=='nhs'||isstaff=='nonNhs')
            ? isUrgentManager
              ? '/book-appointment'
              : '/appointments'
            : '/profile'
        }
      />
    </Switch>
  );
};

const App = (props) => {
  const {
    isInternalServerError,
    decodedToken,
    currentUser,
    isMaintenanceMode,
    location,
    history,
    signOut
  } = props;

  const isBlocked = currentUser?.duplicateCheckStatus === 'blocked';
  // console.log("history and location in app.js",history,location);

  if (isBlocked) {
    signOut();
    localStorage.setItem('blocked', true)
    history.push('/blocked')
  }
  const connectionError = isMaintenanceMode || isInternalServerError

// console.log("in app.js locatoin is",location);
const isSendCommunicationStatus=location.pathname=="/SendCommunication/";

  return (
    <div className="App">
      <Layout>
        <Header />
        <ResendNotification />
        <Suspense fallback={<div />} />
        <Content className="App_Content">
          <ErrorBoundary maintenance={<Maintenance />} internalServer={<InternalServer />}>
            <Suspense fallback={<div />}>
              {!connectionError ? decodedToken ? (
                <PrivateRoutes user={currentUser} history={history} location={location} signOut={signOut} />
              ) : (
                isSendCommunicationStatus?
                (
                  <Redirect
                    to={{
                      pathname: '/sign-in',
                      state: { from: location },
                    }}
                  />
                )
              :
                (
                  <AuthRoutes location={location} />
                )

                ) : isMaintenanceMode
                  ? <LazyMaintenance />
                  : isInternalServerError
                    ? <LazyInternalServer />
                    : null
              }
            </Suspense>
          </ErrorBoundary>
        </Content>

        <Footer />
      </Layout>
    </div>
  );
};


export default App
