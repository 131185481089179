import React from 'react';
import { createComponent } from 'effector-react';

import {
  $isLoading,
  $users,
  $rolesMap,
  $pagination,
  $hospitals,
  $search,
  $selectedRow,
  $isStaffFilter,
  changePagination,
  changeSearch,
  changeSelectedRow,
  $activeTab,
  $isModalVisible,
  setModalVisible,
  $selectedUser,
  activateUser,
  $selectedAction,
  deleteUser,
  resendInvite
} from '../model';

import UsersTable from './UsersTable';


const UsersTableContainer = createComponent(
  {
    $isLoading,
    $users,
    $rolesMap,
    $pagination,
    $hospitals,
    $search,
    $selectedRow,
    $isStaffFilter,
    changePagination,
    changeSearch,
    changeSelectedRow,
    $activeTab,
    $isModalVisible,
    setModalVisible,
    $selectedUser,
    activateUser,
    $selectedAction,
    deleteUser,
    resendInvite
  },
  (props, state) => (
    <UsersTable
      {...props}
      users={state.$users}
      isLoading={state.$isLoading}
      pagination={state.$pagination}
      hospitals={state.$hospitals}
      changePagination={state.changePagination}
      changeSearch={state.changeSearch}
      rolesMap={state.$rolesMap}
      search={state.$search}
      selectedRow={state.$selectedRow}
      changeSelectedRow={changeSelectedRow}
      isStaffFilter={state.$isStaffFilter}
      activeTab={state.$activeTab}
      isModalVisible={state.$isModalVisible}
      setModalVisible={state.setModalVisible}
      selectedUser={state.$selectedUser}
      activateUser={state.activateUser}
      selectedAction={state.$selectedAction}
      deleteUser={state.deleteUser}
      resendInvite={state.resendInvite}
    />
  ),
);

export default UsersTableContainer;
