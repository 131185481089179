import {createStore, createEvent, createEffect} from 'effector';
import get from 'lodash/get';
import {notification} from "antd";

import {
  $isNotificationOpen,
  changeNotificationTimer,
  setNotificationOpen,
  fetchHospitals,
  fetchOrganisations,
  fetchUsers,
} from "../pages/Users/model";
import {client, wsEndpoint} from "../helpers/http";

const necessaryFields = [
  'email',
  'firstName',
  'lastName',
  'phoneNumber',
  'dateOfBirth',
  'ethnicity',
  'ethnicityGroup',
  'organization',
  'position',
  'departments',
  'staff',
];

const generalUserNecessaryFields = [
  'email',
  'firstName',
  'lastName',
  'phoneNumber',
  'dateOfBirth',
  'ethnicity',
  'ethnicityGroup',
  'staff',

];

const necessaryFieldWessex = [
  'email',
  'firstName',
  'lastName',
  'phoneNumber',
  'dateOfBirth',
  'ethnicity',
  'ethnicityGroup',
  'organization',
  'position',
  'departments',
  'staff',
];

const necessaryFieldCommisceo = [
  'email',
  'firstName',
  'lastName',
  'phoneNumber',
  'dateOfBirth',
  'ethnicity',
  'ethnicityGroup',
  'organization',
  'departments',
  'staff',
];

const checkFilledFields = (necessaryArray, checkingObj) => necessaryArray.every(
  (elem) => get(checkingObj, elem, null) !== null
      && get(checkingObj, elem, null) !== '',
);

export const $currentUser = createStore({});
export const $showResendEmailNotif = createStore(true);
export const $mobileModalIsOpen = createStore(false);
export const $isMaintenanceMode = createStore(false);
export const $isInternalServerError = createStore(false);
export const $configs = createStore(null);

export const setShowResendEmailNotif = createEvent();
export const setCurrentUser = createEvent();
export const resetCurrentUser = createEvent();
export const setMobileModalIsOpen = createEvent();
export const setIsMaintenanceMode = createEvent();
export const setIsInternalServerError = createEvent();
export const fetchConfigs = createEvent();

const _fetchConfigs = createEffect();

_fetchConfigs.use(() => client
  .get('/api/v1/configs/')
  .then((response) => get(response, 'data', {}))
  .catch((error) => {
    const message = get(error, 'message', '');
    notification.error({ message });
  }));

$configs.on(_fetchConfigs.done, (_, { result }) => result);


const onMessage = createEvent();
onMessage.watch((data) => {
  if (data?.user_id && window.location.pathname === '/users') {
    if (!$isNotificationOpen.getState()) {
      if (!localStorage.getItem('dontShowUsersModal')) {
        setNotificationOpen(true);
        changeNotificationTimer({time: 60000, disable: false});
      } else {
        fetchHospitals();
        fetchOrganisations();
        fetchUsers();
      }
    }
  }
});

let socket;

$currentUser
  .on(setCurrentUser, (_, params) => {
    const isWessex = params?.account?.uid === 'wessex'
    const isCommisceo = params?.account?.uid === 'commisceo'
    const paramsForChecking = {
        [true]: $currentUser?.allowedStaff=='nhs'? necessaryFields:generalUserNecessaryFields,
        [isCommisceo]: necessaryFieldCommisceo,
        [isWessex]: necessaryFieldWessex
    }
    const isFilled = checkFilledFields(paramsForChecking[true], params);

    return {
      ...params,
      isFilled,
    };
  })
  .reset(resetCurrentUser);

$showResendEmailNotif.on(setShowResendEmailNotif, (_, params) => params);
$mobileModalIsOpen.on(setMobileModalIsOpen, (_, params) => params);
$isMaintenanceMode.on(setIsMaintenanceMode, (_, params) => params);
$isInternalServerError.on(setIsInternalServerError, (_, params) => params);

fetchConfigs.watch(() => _fetchConfigs());

setCurrentUser.watch((data) => {
  const token = localStorage.getItem('access-token-v1');
  const isManager = data?.role.length > 0;
  if (token) fetchConfigs();

  if (token && isManager) {
    if (socket) socket.close(1000, 'reason');
    socket = new WebSocket(`${wsEndpoint()}/ws/v1/users/${data?.account?.uid}/?token=${token}`);

    socket.onmessage = msg => onMessage(JSON.parse(msg.data));
    socket.onopen = msg => {
      console.log(msg, 'connected');
    }
    socket.onclose = msg => {
      console.log(msg);
    }
  } else if(socket) {
    socket.close(1000, 'reason');
  }
});