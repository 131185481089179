import moment from 'moment';
import { camelCase, capitalCase, snakeCase } from 'change-case';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { find } from 'lodash';

import {
  restrictedAppNowSteps,
  restrictedVaccineSteps,
  uidAnalyses,
  isCovidVaccineType
} from '../../helpers/constants';

const statusesText = {
  booked: 'Booked',
  id_verified: 'ID verified',
  arrived: 'Arrived',
  sample_taken: 'Sample taken',
  results_received: 'Results received',
  subject_notified: 'Subject notified',
  patient_reported:'Patient Reported',
  cancelled: 'Cancelled',
  cancelled_blocked: 'Cancelled Blocked',
  did_not_attend: 'Did not Attend'

};

const defaultStatusList = [
  { text: 'Booked', value: 'booked' },
  { text: 'ID verified', value: 'id_verified' },
  { text: 'Arrived', value: 'arrived' },
  { text: 'Sample taken', value: 'sample_taken' },
  { text: 'Results received', value: 'results_received' },
  { text: 'Subject notified', value: 'subject_notified' },
  { text: 'Patient Reported', value: 'patient_reported' },
];

const hiddenStatus = [
  'rebooked',
  'redirected',
  'cancelled',
  'cancelledBlocked',
  'declined',
  'didNotAttend'
];

const hiddenStatuses = {
  rebooked: 'rebooked',
  redirected: 'redirected',
  cancelled: 'cancelled',
  cancelledBlocked: 'cancelled_blocked',
  didNotAttend: 'did_not_attend'
};

const statuses = [
  { text: 'Booked', value: 'booked' },
  { text: 'ID verified', value: 'id_verified' },
  { text: 'Arrived', value: 'arrived' },
  { text: 'Sample taken', value: 'sample_taken' },
  { text: 'Results received', value: 'results_received' },
  { text: 'Subject notified', value: 'subject_notified' },
  { text: 'Patient Reported', value: 'patient_reported' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Redirected', value: 'redirected' },
  { text: 'Rebooked', value: 'rebooked' },
  { text: 'Cancelled Blocked', value: 'cancelled_blocked' },
  { text: 'Did not Attend', value: 'did_not_attend' }
];

const laterallyStatusExeptions = [
  'arrived',
  'results_received'
]

const synonymousUpcomingStatuses = {
  rebooked: 'rebooked',
  redirected: 'redirected',
  booked: 'booked',
};

export const getStatusData = (appointment) => {
  const isCanselled = some(appointment.statusHistories, (statusHistory) => ['cancelled', 'cancelled_blocked', 'did_not_attend'].includes?.(statusHistory.value));
  const actualStatuses = statuses
  let currentStatus = null;

  const isLatteraly = appointment.analyse.uid === uidAnalyses.laterally

  if (Object.keys(hiddenStatuses).indexOf(appointment?.status) === -1) {
    currentStatus = actualStatuses.findIndex((s) => s.value === appointment?.status) + 1;
  } else if (
    Object.keys(synonymousUpcomingStatuses)?.includes?.(appointment?.status)
  ) {
    currentStatus = 1;
  } else {
    currentStatus = findIndex(
      statuses,
      (status) => status.value === appointment?.status,
    );
  }

  const isCovidVaccine = isCovidVaccineType(appointment?.analyse?.uid);
  const isMaskFitting = appointment.analyse.uid === 'ffp3_mask_fitting';
  if (isCanselled) {
    const statusList = appointment.statusHistories
      .filter(
        (statusHistory) => !['rebooked', 'redirected'].includes?.(statusHistory.value),
      )
      .map((statusHistory) => ({
        ...statusHistory,
        text: statusesText[statusHistory.value],
        status: ['cancelled', 'cancelled_blocked', 'did_not_attend'].includes?.(
          statusHistory.value,
        )
          ? 'error'
          : null,
      }));



    return {
      statusList,
      currentStatus,
    };
  }

  if (
    isCovidVaccine || isMaskFitting
  ) {
    const statusList = defaultStatusList.filter(
      (statusHistory) => !restrictedVaccineSteps?.includes?.(statusHistory.value),
    );

    return {
      statusList,
      currentStatus,
    };
  }

  if (isLatteraly) {

    // currentStatus = actualStatuses
    // .filter(
    //   (statusHistory) => !laterallyStatusExeptions?.includes?.(statusHistory.value),
    // ).findIndex((s) => s.value === appointment?.status) + 1;

    const statusList = defaultStatusList.filter(
      (statusHistory) => !laterallyStatusExeptions?.includes?.(statusHistory.value),
    );

    return {
      statusList,
      currentStatus,
    };
  }


  if (appointment?.aptNow) {
    const statusList = defaultStatusList.filter(
      (statusHistory) => !restrictedAppNowSteps?.includes?.(statusHistory.value),
    );

    return {
      statusList,
      currentStatus,
    };
  }

  return {
    statusList: defaultStatusList,
    currentStatus,
  };
};

export const getRenderDoB = (dateOfBirth) => {
  const date = new Date(dateOfBirth);
  if (isNaN(date.getTime())) return null;
  return new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(dateOfBirth));
};

export const getRenderSymptomsDate = (appointment) => {
  let symptomsDate = null;
  let startDate = '';
  let endDate = '';

  if (appointment.symptomsStartDate) {
    startDate = moment(appointment.symptomsStartDate).format('DD MMM YYYY');
  }

  if (appointment.symptomsEndDate) {
    endDate = moment(appointment.symptomsEndDate).format('DD MMM YYYY');
  }

  if (startDate && endDate) {
    symptomsDate = `Started ${startDate} - Ended ${endDate}`;
  } else if (startDate && !endDate) {
    symptomsDate = `Started ${startDate}`;
  } else if (!startDate && endDate) {
    symptomsDate = `Ended ${endDate}`;
  } else {
    symptomsDate = '';
  }

  return symptomsDate;
};

export const getRenderSymptoms = (appointment) => {
  const { symptoms } = appointment;
  const renderSymptoms = reverse(
    sortBy(symptoms, (symptom) => symptom.category === 'high'),
  );

  return isEmpty(renderSymptoms) ? null : renderSymptoms;
};

const titleMap = {
  booked: 'Appointment booked',
  id_verified: 'ID verified',
  arrived: 'Patient arrived',
  sample_taken: 'Sample taken',
  results_received: 'Results received',
  subject_notified: 'Subject notified',
  patient_reported:"Patient Reported",
  rebooked: 'Appointment rebooked',
  redirected: 'Appointment redirected',
  cancelled: 'Cancelled',
  cancelled_blocked: 'Cancelled Blocked',
  declined: 'Appointment declined',
  did_not_attend: "Did not Attend"

};

export const getTimeline = (appointment, currentStatus, statusChoices) => {
  const { statusHistories } = appointment;
  let timeline = [];

  const isCanselled = some(statusHistories, (statusHistory) => ['cancelled', 'cancelled_blocked', 'did_not_attend'].includes?.(statusHistory.value));
  const isDeclined = find(statusHistories, ['value', 'declined']);

  const oldStatuses = statusHistories.map((s) => ({
    ...s,
    title: titleMap[s.value],
    time: moment(s.created).format('HH:mm'),
    date: moment(s.created).format('ddd D MMM YYYY'),
    future: false,
  }));

  const futureStatuses = Object.keys(statusChoices)
    .map((key) => ({
      title: statusChoices[camelCase(key)],
      status: key,
      time: 'Time',
      date: 'Date',
      future: true,
      value: snakeCase(key),
    }))
    .filter(
      (s, index) => !hiddenStatus?.includes?.(s.status) && index > currentStatus + 1,
    );

  if (isCanselled) {
    timeline = [...oldStatuses];
  } else {
    timeline = [...oldStatuses, ...futureStatuses];
  }

  if (isDeclined) {
    timeline = [...oldStatuses];
  }

  return timeline;
};

export const prepareAppointment = (appointmentData, configs, currentStatus, user) => {
  const appointment = cloneDeep(appointmentData);

  if (appointment?.analyse?.uid === uidAnalyses.antibody) {
    appointment.isAntibodyTest = true;
  } else {
    appointment.isAntibodyTest = false;
  }

  const isRelationship = !isEmpty(appointment.relationship);

  const isCovidVaccine = isCovidVaccineType(appointment.analyse.uid);

  appointment.isBlueMark = (!appointment.consentApproval && appointment.analyse.uid === uidAnalyses.antibody)
    || (appointment.checklistMissing && appointment.analyse.uid === uidAnalyses.fluVaccine)
    || (appointment.checklistMissing && appointment.analyse.uid === isCovidVaccine
      && appointment.vaccinationPart === 1);
  appointment.isRedMark = isRelationship ? !appointment.verification : appointment.userVerification !== 'verified';
  appointment.renderDoB = getRenderDoB(appointment.dateOfBirth);
  appointment.renderGender = capitalCase(appointment.gender);
  appointment.renderEthnicityGroup = configs.ethnicityGroupChoices[camelCase(appointment.ethnicityGroup)];
  appointment.renderEthnicity = configs.ethnicityChoices[camelCase(appointment.ethnicity)];
  appointment.renderSymptomsDate = getRenderSymptomsDate(appointment);
  appointment.renderSymptoms = getRenderSymptoms(appointment);

  return appointment;
};
