import React, { memo,useCallback, useMemo, useState,useEffect } from 'react';
import classnames from 'classnames';
import { Spin } from 'antd';
import { isCovidVaccineType } from '../../../../helpers/constants'
import UsersTable from '../../../../pages/Users/UsersTable';
import './index.scss'
import { useHistory } from 'react-router-dom';
import {
  Button, Table, Tabs, Space,Modal,Form, Input,notification,Tooltip, Row, Col,Select,
} from 'antd';
const { Item } = Form;
import { client } from '../../../../helpers/http';
import UserNameCell from '../../../../pages/Users/components/UserNameCell';
import { orderBy,get } from 'lodash';
// import {

//   $users,
//   $pagination,
//   $isLoading,
//   changePagination,
// } from '../../../../pages/Users/model';

import {

  getAllOrganizations,

} from '../../../../state/user/api';

import {
  onSelectChange,
  createSelectOptions,

} from '../../../../helpers';
import Labeled from '../../../Labeled';

const ScheduleButton = ({
  analyseUid,
  isTCmanager,
  isSuperManager,
  isAdHoc,
  isAptNow,
  slot,
  isAdHocRebook,
  isAdHocReschedule,
  vaccinationPart,
  onSlotChange,
  secondPartDisabled,
  selectedTime,
  isFromMyTests,
  isFromCCB,
  testCenter,
  procedure,
  datas,
  organizationDropdown,
  count,

}) => {
  console.log("users is cant ",datas);

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [data, setdata] = useState([]);
  const [slotDate, setSlotDate] = useState("");
  const [slotTime, setSlotTime] = useState("");
  const[selectedRowsData,setSelectedRowsData] = useState([]);
  const[searchedValue,setSearchedValue] = useState("");
  // const [organizationDropdown, setOrganizationDropdown] = useState([]);
  const [organization, setOrganization] = useState();
  // const [count, setCount] = useState(0);

  const [form] = Form.useForm();
  const isManager = isSuperManager    //removed isTCmanager || from line
  const isInvalid = slot?.status === "invalid"
  const bookInAdvance = isAdHoc && !isAptNow
  const history = useHistory();
  const isManagerFlow = !((isManager && isAdHocRebook) || isAdHocReschedule || bookInAdvance || isAptNow)
  const isRestrictedMangerFlow = isFromCCB || isFromMyTests

  const isCovidVaccine = isCovidVaccineType(analyseUid);

  const canOverbookByRoles = {
    SM: (vaccinationPart === 2 || isRestrictedMangerFlow || isManagerFlow) ? false : isManager,
    TC: (isCovidVaccine || isRestrictedMangerFlow || isManagerFlow) ? false : isManager,
    others: false
  }

let configUrl=window.location.href;
console.log("configUrl",configUrl);
let UrlUID=false;
if(configUrl.includes("schedule")){
  UrlUID=true;
}

  // const availableForOverbooking = isSuperManager ? canOverbookByRoles.SM : isTCmanager ? canOverbookByRoles.TC : canOverbookByRoles.others
  console.log("availableForOverbooking is",availableForOverbooking,analyseUid);
  const availableForOverbooking = isManager;
  const availableNumber = slot?.available <= 0
    ? availableForOverbooking
      ? slot?.available
      : 0 : slot?.available
  console.log("availableNumber is",availableNumber);
  const isDisabled =
    !availableForOverbooking && slot?.available <= 0
    || !slot
    || slot.fake
  console.log("isDisabled is",isDisabled);

  if (slot?.fake || !slot) {
    return (<div className="slot_disabled" />)
  }

  const handleCancelModal = (value) => {
    console.log("value is",slot);
    setSlotDate(slot.date);
    setSlotTime(slot.slot);
    form.resetFields();
    setIsOpenCancelModal(value)

  };

  // const loadUsers=async()=>{
  //   let response=await client.get(`/api/v1/users/?consent_approval=&duplicate_check_statuses=in_progress%2Csucceeded&management=true&limit=500000&offset=10&ordering=first_name&search=&verifications=verified%2Cin_progress%2Cnot_verified`);
  //        console.log("count is",count);
  //        // setCount(response.data.count);
  //        // console.log("33333333",response.data.results);
  //        setdata(response.data.results);
  // }
  // useEffect(async()=>{
  //   loadUsers();

  //  },[]);


  // const columns = useMemo(() => [
  //   {
  //     key: 'email',
  //     title: 'Users',
  //     width: 270,
  //     ellipsis: true,
  //     className: 'usersHeadline',
  //     filteredValue:[searchedValue],
  //     onFilter:(value,record)=>{
  //       return (
  //         console.log("value is",value),

  //         record?.organization?.id==value ||
  //         String(record.email).toLowerCase().includes(value.toLowerCase())
  //       );
  //     },
  //     // visible: usersTableVisibleParams.all,
  //      render: (_, record) => record?.email,
  //   },

  //   {
  //     key: 'organization',
  //     title: 'Organization',
  //     ellipsis: true,
  //     // visible: usersTableVisibleParams.patients,
  //     // filteredValue:[organization],
  //     // onFilter:(value,record)=>{
  //     //   return (
  //     //     console.log("value is",value),
  //     //     // String(record?.organization?title).toLowerCase().includes(value.toLowerCase())
  //     //      record?.organization?.id==organization
  //     //   )
  //     // },
  //     render: (_, record) => record?.organization?.title,
  //   },



  // ]);


  // useEffect(() => {

  //   getAllOrganizations().then((organizations) => {
  //     setOrganizationDropdown(organizations.data.results);
  //   });
  // }, []);

  const setOrganisationField = (organization) => {
    setOrganization(organization);
    console.log("orf",organization);

  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsData(selectedRowKeys);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }};

const handleConfirm = async()=>{
  console.log("hello asif",selectedRowsData.id,selectedRowsData.length);
  let list=[]
  for(let i=0;i<=selectedRowsData.length;i++){
    list.push(selectedRowsData.id);
  }
 let data={
    'list':selectedRowsData,
    'procedure':procedure,
    'hospital':testCenter,
    'date':slotDate,
    'time':slotTime,

  }

  await client.post(`/api/v1/send_email/`,data);

  setIsOpenCancelModal(false);

}

  return (
    <div
      className={classnames({
        disabled: isDisabled,
        active: !!slot?.time?.isSame?.(selectedTime?.time),
        scheduleButton: true,
        notAvailable:
          ((isCovidVaccine && !isTCmanager) || isSuperManager) && (
            (slot?.available <= 0 && !isAdHoc && !isAptNow) ||
            (slot?.available <= 0 && isAdHoc && isTCmanager) ||
            isAdHocRebook ||
            isAdHoc ||
            isAdHocReschedule ||
            isAptNow),
        'vaccinationPartButton-2': vaccinationPart === 2,
        noCapacity: !slot?.capacity,
      })}
      onClick={() => (isDisabled || isInvalid) ? null : onSlotChange(slot)}
    >
      <div className="slot_container">
        <div
          className={classnames({
            slot_available: true,
            [slot?.colour || '']: !isInvalid,
            invalid: isInvalid
          })}


          //  onClick={UrlUID?e=>handleCancelModal(true):undefined}
        >
          <div className="slot_available_number">{availableNumber}</div> <div className="slot_available_text">{availableNumber < 0 ? 'overbooked' : 'available'}</div>
        </div>
        {(isManager && !isFromMyTests) && <div className="slot_booked"><div className="slot_booked_number">{slot?.booked === 0 ? 0 : slot?.booked || ""}</div><div className="slot_booked_text">booked</div> </div>}
      </div>

      <Modal
        width={1000}
        visible={isOpenCancelModal}
        onCancel={() => handleCancelModal(false)}
        onOk={form.submit}
        okText="Send Email"
        cancelButtonProps={{ className: 'modal-cancel__cancelBtn' }}
        okButtonProps={{ className: 'modal-cancel__confirmBtn' }}
        centered
      >
        <Form
          form={form}
          onFinish={handleConfirm}
          className='modal-cancel'
        >

        <Row>
          <Col xs={24} md={12}>
          <Labeled>Email</Labeled>
        <Input.Search
        placeholder='Enter email to search'
        style={{marginBottom:10}}
        onSearch={(value)=>{
          setSearchedValue(value);
        }}
        onChange={(e)=>{
          setSearchedValue(e.target.value);
        }}
        >

        </Input.Search>
        </Col>
        <Col xs={24} md={12}>
          <Labeled>Organization</Labeled>
        <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  // disabled={userHasOrg && !isSuper}
                  showSearch
                  // onSearch={onSearch}
                  // optionFilterProp="children"
                  // filterOption={(input, option) => {
                  //   return (
                  //     option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  //     option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  //   );

                  // }}
                  value={organization}
                  placeholder="Select organisation"
                  style={{marginBottom:10,width:400,marginLeft:4}}
                  // onChange={onSelectChange(setOrganisationField)}
                  // onSearch={(value)=>{
                  //   onSelectChange(setOrganisationField(value))
                  // }}
                  onChange={(e)=>{
                    setSearchedValue(e)
                  }}
                >
                  {createSelectOptions(organizationDropdown)}
                </Select>
        </Col>
        </Row>

        <Table
        rowKey="id"
        // columns={columns}
        dataSource={datas}
        pagination={true}
        // onChange={changePagination}
       loading={datas.length>0?false:true}
        // onRow={onRow}

        rowSelection={{
          type:'checkbox',
          ...rowSelection,
          onselect:(record)=>{
            console.log(record)
          }
        }
        }
        scroll={{ x: 1200 }}
      >
        </Table>

        <h3>Total Users are: {count}</h3>
        <h3>Selected Users are: {selectedRowsData.length}</h3>
        </Form>



      </Modal>

    </div>
  )



}

// start modal




// end modal



ScheduleButton.displayName = 'ScheduleButton'

export default memo(ScheduleButton);
